import React from 'react';
import Footer from '../../components/Footer.js';
import PMyOrders from '../../components/EmptyMyOrders.js';
import WithOrders from '../../components/WithOrders.js';
import EditProfile from '../../components/EditProfile.js';
import OrdersMobile from '../../components/OrdersMobile.js';

const Test = () => {
    return (
        <div className='bg-creamWhite'>
            <div className='flex justify-center items-center h-screen'>
               <OrdersMobile/>
            </div>
            <div>
                <Footer />
            </div>

            <p>
            
            </p>
            
            

            
        </div>
    );
}

export default Test;
