import React from "react";

const BoxCard = ({ title, msg }) => {
  return (
    <div className="bg-gray-100 xl:bg-gradient-to-r to-gray-100 from-white w-[330px] rounded-2xl p-6">
      <div className="font-bold text-center xl:text-right xl:text-xl">
        {title}
      </div>
      <div className="text-center xl:text-right text-sm">
        {msg}
      </div>
    </div>
  );
};

export default BoxCard;
