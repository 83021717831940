// import React from "react";
// import { useNavigate } from "react-router-dom";
// import { Cookies } from "react-cookie";
// import { AuthState } from "../context/authContext";

// const Logout = () => {
//   const navigate = useNavigate();
//   const cookies = new Cookies();
//   const { setUser } = AuthState();

//   const handleLogout = async () => {
//     try {
//       const response = await fetch("https://backend.mealprep.sultanahmet.ca/api/v1/auth/signout", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });

//       if (response.ok) {
//         // Clear cookies
//         cookies.remove("mealprep-session");
//         cookies.remove("mealprep-refresh");

//         // Clear local storage
//         localStorage.removeItem("LOGGEDIN_USER");

//         // Clear user context
//         setUser(null);

//         // Navigate to the login page
//         navigate("/login");
//       } else {
//         console.error("Failed to logout. Server returned:", response.status);
//         // Handle error response from the backend
        
//       }
//     } catch (error) {
//       console.error("Error occurred while logging out:", error);
//       // Handle any network errors or exceptions
//     }
//   };

//   return (
//     <button onClick={handleLogout} className="logout-button">
//       Logout
//     </button>
//   );
// };

// export default Logout;

// In Logout.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { AuthState } from "../context/authContext";

const Logout = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const { setUser } = AuthState();

  const handleLogout = () => {
    // Logout logic
    cookies.remove('mealprep-session');
    cookies.remove('mealprep-refresh');
    localStorage.removeItem('LOGGEDIN_USER');
    setUser(null);
    navigate('/login');
  };

  return (
    <button onClick={handleLogout} className="logout-button link font-semibold text-xl">
      LOG OUT
    </button>
  );
};

export default Logout;
