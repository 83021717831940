// import { yupResolver } from "@hookform/resolvers/yup";
// import React from "react";
// import { useForm } from "react-hook-form";
// import * as yup from "yup";
// import { validateEmail } from "../services/authService";

// const registerSchema = yup.object({
//   email: yup
//     .string()
//     .email("Email format is not valid")
//     .required("Email is required"),
//   password: yup
//     .string()
//     .min(5, "Password must be at least 5 characters")
//     .required("Password is required"),
// });

// const Register = ({ setCurrentStep, setRegisterForm }) => {
//   const {
//     register,
//     handleSubmit,
//     setError,
//     formState: { errors },
//   } = useForm({
//     defaultValues: {
//       email: "",
//       password: "",
//     },
//     resolver: yupResolver(registerSchema),
//   });

//   const onSubmit = async (data) => {
//     try {
//       const res = await validateEmail({ email: data.email });
//       // console.log(res.data.data);

//       if (res.data.data.isAlreadyTaken) {
//         setError("root", {
//           message: res.data.data.message,
//         });
//         throw Error(res.data.data.message);
//       }

//       // console.log(data);

//       setRegisterForm(data);
//       setCurrentStep(3);
//     } catch (e) {
//       console.log(e);
//     }
//   };

//   return (
//     <>
//       <form onSubmit={handleSubmit(onSubmit)}>
//         <div className="text-start pb-7 border-b">
//           <p className="text-2xl font-bold mb-2">Register</p>
//           <p>
//             Register now to unlock exclusive benefits and personalize your
//             experience, making your journey with us even more enjoyable.
//           </p>
//         </div>
//         <div className="xl:w-[400px] mx-auto my-10">
//           <h2 className="text-lg font-bold">Create your MealPrep account</h2>
//           <div className="form-control w-full my-3">
//             <input
//               type="email"
//               className="border border-[#E3E3E3] rounded-lg text-xs w-full h-[42px] px-4"
//               {...register("email")}
//               placeholder="Email Address"
//             />
//             {errors.email && (
//               <div className="text-left text-xs text-red-500 my-2">
//                 {errors.email?.message}
//               </div>
//             )}
//           </div>
//           <div>
//             <input
//               type="password"
//               className="border border-[#E3E3E3] rounded-lg text-xs w-full h-[42px] px-4"
//               {...register("password")}
//               placeholder="Password"
//             />
//             {errors.password && (
//               <div className="text-left text-xs text-red-500 my-2">
//                 {errors.password?.message}
//               </div>
//             )}
//           </div>
//           <p className="text-xs mt-2 text-start">
//             Password should be a minimum of 5 characters
//           </p>
//           <button className="bg-black w-full h-[36px] text-white rounded-[8px] text-xs mt-6">
//             Register
//           </button>
//           {errors.root && (
//             <div className="text-left text-xs text-red-500 my-2">
//               {errors.root?.message}
//             </div>
//           )}
//           <p className="text-sm text-center mt-3">
//             By continuing, you agree to receive marketing emails and our Terms
//             and Privacy Policy
//           </p>
//         </div>
//       </form>
//     </>
//   );
// };

// export default Register;

// import React, { useState } from "react";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
// import {
//   validateEmail,
//   signIn,
//   signup,
//   getCurrentUser,
// } from "../services/authService";
// import { setCurrentUser } from "../services/authService";
// import { AuthState } from "../context/authContext";
// import { useNavigate } from "react-router-dom";

// const registerSchema = yup.object({
//   email: yup
//     .string()
//     .email("Email format is not valid")
//     .required("Email is required"),
//   password: yup
//     .string()
//     .min(5, "Password must be at least 5 characters")
//     .required("Password is required"),
// });

// const Register = ({ setCurrentStep, setRegisterForm }) => {
//   const [loading, setLoading] = useState(false);
//   const [isExistingUser, setIsExistingUser] = useState(false);
//   const { setUser } = AuthState();
//   const navigate = useNavigate();

//   const {
//     register,
//     handleSubmit,
//     setError,
//     formState: { errors },
//   } = useForm({
//     defaultValues: {
//       email: "",
//       password: "",
//     },
//     resolver: yupResolver(registerSchema),
//   });

//   const onSubmit = async (data) => {
//     setLoading(true);

//     try {
//       const emailValidationResponse = await validateEmail({
//         email: data.email,
//       });

//       if (emailValidationResponse.data.data.isAlreadyTaken) {
//         setIsExistingUser(true); // Set to true to prompt for login
//         // Attempt login for existing user
//         try {
//           const loginResponse = await signIn(data);
//           setCurrentUser(loginResponse.data.data.user);
//           setUser(loginResponse.data.data.user);
//           getCurrentUser(loginResponse.data.data.user);
//           // window.location.reload();
//           // navigate("/Steps");
//           setCurrentStep(3);
//         } catch (loginError) {
//           setError("root", {
//             message:
//               loginError?.response?.data?.message ||
//               "Login failed. Please check your credentials.",
//           });
//         }
//       } else {
//         // Proceed with registration if the email is not taken
//         // const registrationResponse = await signup(data);
//         // setCurrentUser(registrationResponse.data.data.user);
//         // setUser(registrationResponse.data.data.user);
//         // setRegisterForm(data);
//         //setCurrentStep(3); // Move to the next step (Address step)

//         const res = await validateEmail({ email: data.email });
//         // console.log(res.data.data);

//         if (res.data.data.isAlreadyTaken) {
//           setError("root", {
//             message: res.data.data.message,
//           });
//           throw Error(res.data.data.message);
//         }

//         // console.log(data);

//         setRegisterForm(data);
//         setCurrentStep(3);
//       }
//     } catch (error) {
//       setError("root", {
//         message: error?.response?.data?.message || "Something went wrong!",
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <>
//       <form onSubmit={handleSubmit(onSubmit)}>
//         <div className="text-start pb-7 border-b">
//           <p className="text-2xl font-bold mb-2">Register / Login</p>
//           <p>
//             {isExistingUser
//               ? "Welcome back! Please enter your password to log in."
//               : "Register now to unlock exclusive benefits and personalize your experience."}
//           </p>
//         </div>
//         <div className="xl:w-[400px] mx-auto my-10">
//           <h2 className="text-lg font-bold">
//             {isExistingUser
//               ? "Log in to your MealPrep account"
//               : "Create your MealPrep account"}
//           </h2>
//           <div className="form-control w-full my-3">
//             <input
//               type="email"
//               className="border border-[#E3E3E3] rounded-lg text-xs w-full h-[42px] px-4"
//               {...register("email")}
//               placeholder="Email Address"
//               disabled={isExistingUser} // Disable email field if user exists
//             />
//             {errors.email && (
//               <div className="text-left text-xs text-red-500 my-2">
//                 {errors.email?.message}
//               </div>
//             )}
//           </div>
//           <div>
//             <input
//               type="password"
//               className="border border-[#E3E3E3] rounded-lg text-xs w-full h-[42px] px-4"
//               {...register("password")}
//               placeholder={isExistingUser ? "Enter your password" : "Password"}
//             />
//             {errors.password && (
//               <div className="text-left text-xs text-red-500 my-2">
//                 {errors.password?.message}
//               </div>
//             )}
//           </div>
//           <p className="text-xs mt-2 text-start">
//             {isExistingUser
//               ? ""
//               : "Password should be a minimum of 5 characters"}
//           </p>
//           <button
//             className="bg-black w-full h-[36px] text-white rounded-[8px] text-xs mt-6"
//             type="submit"
//             disabled={loading}
//           >
//             {loading ? "Loading..." : isExistingUser ? "Log in" : "Submit"}
//           </button>
//           {errors.root && (
//             <div className="text-left text-xs text-red-500 my-2">
//               {errors.root?.message}
//             </div>
//           )}
//           {!isExistingUser && (
//             <p className="text-sm text-center mt-3">
//               By continuing, you agree to receive marketing emails and our Terms
//               and Privacy Policy.
//             </p>
//           )}
//         </div>
//       </form>
//     </>
//   );
// };

// export default Register;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  validateEmail,
  signIn,
} from "../services/authService";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material"; // Importing MUI Dialog for the popup

const registerSchema = yup.object({
  email: yup
    .string()
    .email("Email format is not valid")
    .required("Email is required"),
  password: yup
    .string()
    .min(5, "Password must be at least 5 characters")
    .required("Password is required"),
});

const Register = ({ setCurrentStep, setRegisterForm }) => {
  const [loading, setLoading] = useState(false);
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); // State for dialog
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(registerSchema),
  });

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const emailValidationResponse = await validateEmail({
        email: data.email,
      });

      if (emailValidationResponse.data.data.isAlreadyTaken) {
        setIsExistingUser(true); // Set state to show existing user fields
        setOpenDialog(true); // Open the dialog to prompt login
      } else {
        // If email is not taken, proceed with registration
        setRegisterForm(data);
        setCurrentStep(3); // Move to next step
      }
    } catch (error) {
      setError("root", {
        message: error?.response?.data?.message || "Something went wrong!",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLoginRedirect = () => {
    setOpenDialog(false); // Close the dialog
    navigate("/login"); // Redirect to login page
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="text-start pb-7 border-b">
          <p className="text-2xl font-bold mb-2">Register</p>
          <p>
            {isExistingUser
              ? "Welcome back! Please enter your password to log in."
              : "Register now to unlock exclusive benefits and personalize your experience."}
          </p>
        </div>
        <div className="xl:w-[400px] mx-auto my-10">
          <h2 className="text-lg font-bold">
            {isExistingUser
              ? "Log in to your MealPrep account"
              : "Create your MealPrep account"}
          </h2>
          <div className="form-control w-full my-3">
            <input
              type="email"
              className="border border-[#E3E3E3] rounded-lg text-xs w-full h-[42px] px-4"
              {...register("email")}
              placeholder="Email Address"
              disabled={isExistingUser} // Disable email field if user exists
            />
            {errors.email && (
              <div className="text-left text-xs text-red-500 my-2">
                {errors.email?.message}
              </div>
            )}
          </div>
          <div>
            <input
              type="password"
              className="border border-[#E3E3E3] rounded-lg text-xs w-full h-[42px] px-4"
              {...register("password")}
              placeholder={isExistingUser ? "Enter your password" : "Password"}
            />
            {errors.password && (
              <div className="text-left text-xs text-red-500 my-2">
                {errors.password?.message}
              </div>
            )}
          </div>
          <p className="text-xs mt-2 text-start">
            {isExistingUser
              ? ""
              : "Password should be a minimum of 5 characters"}
          </p>
          <button
            className="bg-black w-full h-[36px] text-white rounded-[8px] text-xs mt-6"
            type="submit"
            disabled={loading}
          >
            {loading ? "Loading..." : isExistingUser ? "Log in" : "Submit"}
          </button>
          {errors.root && (
            <div className="text-left text-xs text-red-500 my-2">
              {errors.root?.message}
            </div>
          )}
          {!isExistingUser && (
            <p className="text-sm text-center mt-3">
              By continuing, you agree to receive marketing emails and our Terms
              and Privacy Policy.
            </p>
          )}
        </div>
      </form>

      {/* Dialog for Existing User */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <div className="p-6 text-center">
          <h2 className="text-xl font-bold">Existing Member Identified !</h2>
          <p className="my-4">
           Please login with your credentials before put the order.
          </p>
          <button
            className="bg-redlight text-white px-4 py-2 rounded-md"
            onClick={handleLoginRedirect}
          >
            Go to Login
          </button>
        </div>
      </Dialog>
    </>
  );
};

export default Register;