import React from "react";
import { useNavigate } from "react-router-dom";

const MenuCard = ({ imgUrl, data }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/meals/${data.id}`)} 
      className="bg-white border border-[#EFEFEF] rounded-xl p-3 cursor-pointer"
    >
      {/* image */}
      <div className="overflow-hidden rounded-xl">
        <img className="w-full" src={imgUrl} alt="MealPic" />
      </div>
      {/* card info */}
      <div className="my-5">
        {/* title */}
        <p className="text-lg font-bold text-start mb-2">{data?.name}</p>
        {/* tags */}
        <div className="flex items-start space-x-1">
          {data.categories && data?.categories.map((c) => (
            <div
              key={c.id}
              className="bg-FillIng2 text-OrangeIng2 rounded-full text-center py-1 px-3"
            >
              <p className="text-xs">{c?.name}</p>
            </div>
          ))}
          {/* <div className="bg-FillIng1 text-redIng1 rounded-full text-center py-1 px-3">
            <p className="text-xs">Keto</p>
          </div>
          <div className="bg-FillIng2 text-OrangeIng2 rounded-full text-center py-1 px-3">
            <p className="text-xs">Protein</p>
          </div>{" "}
          <div className="bg-FillIng3 text-GreenIng3 rounded-full text-center py-1 px-3">
            <p className="text-xs">Veg</p>
          </div> */}
        </div>
        {/* stats */}
        <div className="hidden  grid-cols-4 gap-1 border-[#E7E7E7] divide-x my-6 ">
          <div>
            <p className="font-bold">140</p>
            <p className="text-xs">Calories</p>
          </div>
          <div>
            <p className="font-bold">30g</p>
            <p className="text-xs">Net Carbs</p>
          </div>
          <div>
            <p className="font-bold">26g</p>
            <p className="text-xs">Protein</p>
          </div>
          <div>
            <p className="font-bold">4g</p>
            <p className="text-xs">Fat</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuCard;

