import React, { useEffect, useState } from "react";
import meal12 from "../images/image 12.png";
import { filterByCategoriesMeals } from "../services/mealService";
import { createOrder } from "../services/orderService";
import { useNavigate } from "react-router-dom";

const SelectMeal = ({ payload, setPayload }) => {
  const [meals, setMeals] = useState([]);
  const [mealsCount, setMealsCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(8);

  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const changeItemsPerPage = () => {
    const itemCount = itemsPerPage === 8 ? mealsCount : 8;
    setItemsPerPage(itemCount);
  };

  const getMeals = async () => {
    let str = "";
    // console.log(payload.categoryIds);
    payload.categoryIds.forEach((i) => (str = str + `categoryIds=${i}&`));

    try {
      const res = await filterByCategoriesMeals(str, itemsPerPage);
      // console.log(res.data.data.list);
      setMeals(res.data.data.list);
      setMealsCount(res.data?.data?.count);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    let updatedMeals = [...data];

    if (checked) {
      updatedMeals.push(name);
    } else {
      updatedMeals = updatedMeals.filter((m) => m !== name);
    }

    setData(updatedMeals);
  };

  const addOrder = async (formdata) => {
    try {
      const res = await createOrder(formdata);

      window.open(res.data.data.url, "_blank");
      navigate(
        `/checkout/${res.data.data.metadata.orderId}/${res.data.data.id}`
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async () => {
    // console.log(data);
    await setPayload({ ...payload, mealIds: data });
    await addOrder({ ...payload, mealIds: data });
  };

  useEffect(() => {
    getMeals();
  }, [itemsPerPage]);

  return (
    <>
      <div className="pb-7 border-b">
        <div className="flex justify-between items-center">
          <div className="text-start">
            <p className="text-2xl font-bold mb-2">Select Meal</p>
            <p>Explore a tempting array of culinary options and personalize your dining experience by selecting your preferred meals.</p>
          </div>
          <button
            type="submit"
            className="bg-black text-white h-10 rounded-[8px] px-8 disabled:cursor-not-allowed disabled:bg-black/60"
            onClick={() => {
              onSubmit();
            }}
            disabled={data.length < 1}
          >
            <p>Place Order</p>
          </button>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-3 my-10">
        {/*  */}
        {meals.map((m) => (
          <label
            key={m.id}
            htmlFor={m.id}
            className="h-28 border rounded-[8px] p-2 flex justify-between items-center cursor-pointer select-none relative"
          >
            <div className="space-y-2 ml-4">
              <p className="font-bold text-left">{m.name}</p>
              <div className="flex items-start gap-1">
                <div className="bg-FillIng1 text-redIng1 rounded-full text-center py-1 px-3">
                  <p className="text-xs">Keto</p>
                </div>
                <div className="bg-FillIng2 text-OrangeIng2 rounded-full text-center py-1 px-3">
                  <p className="text-xs">Protein</p>
                </div>{" "}
                <div className="bg-FillIng3 text-GreenIng3 rounded-full text-center py-1 px-3">
                  <p className="text-xs">Veg</p>
                </div>
              </div>
            </div>
            <div className="overflow-hidden rounded-[8px] size-[96px]">
              <img
                src={m.imageUrl}  // Dynamically set the image URL here
                alt={m.name}  // Optionally use the meal name as alt text
                className="w-full h-full object-cover"
              />
            </div>
            <input
              className="accent-[#E30032] size-4 absolute top-1 right-1 m-1"
              type="checkbox"
              id={m.id}
              name={m.id}
              checked={data.includes(m.id)}
              onChange={handleCheckboxChange}
            />
          </label>
        ))}
      </div>
      <div className="flex justify-center mt-10">
        <button
          className="btn-outline"
          onClick={changeItemsPerPage}
          disabled={mealsCount === 0}
        >
          {itemsPerPage === 8 ? "Show more" : "Show less"}
        </button>
      </div>
    </>
  );
};

export default SelectMeal;