import React from "react";
import Footer from "../components/Footer";
import { Link, Outlet, useLocation } from "react-router-dom";
import { HiChevronRight } from "react-icons/hi";
import { AuthState } from "../context/authContext";

const accountLinks = [
  {
    name: "My Profile",
    endpoint: "/profile",
  },
  {
    name: "My Orders",
    endpoint: "/orders",
  },
];

function AccountLayout() {
  const location = useLocation();
  const {user} = AuthState();
  //console.log(user);

  return (
    <>
      <div className="bg-creamWhite py-10 xl:py-20">
        <div className="flex flex-col xl:flex-row justify-center xl:gap-10 ">

          {/* //*XL screen naviation */}
          <div className="bg-white w-[350px] h-[680px] rounded-[20px] justify-center items-center p-8 xl:block hidden">
            <p className="text-[24px] font-bold text-start">Hi {user.firstName},</p>
            <div className="flex flex-col gap-4 my-8">
              {accountLinks.map((l, idx) => (
                <Link
                  key={idx}
                  className={`${
                    location.pathname === l.endpoint
                      ? "bg-[#F5F5F5]"
                      : "bg-white"
                  } rounded-[15px] cursor-pointer flex justify-between items-center h-14 px-4`}
                  to={l.endpoint}
                >
                  <p>{l.name}</p>
                  <HiChevronRight size={24}/>
                </Link>
              ))}
            </div>
          </div>
          {/* //*Mobile screen naviation */}
          <div className=" block xl:hidden p-8">
            <p className="font-bold text-[18px] text-start">Hi {user.firstName},</p>
            <div className="flex gap-4 my-8 justify-center">
              {accountLinks.map((l, idx) => (
                <Link
                  key={idx}
                  className={`${
                    location.pathname === l.endpoint
                      ? "bg-black text-white" 
                      : ""
                  } rounded-[15px] cursor-pointer flex justify-between items-center h-14 px-4 font-semibold`}
                  to={l.endpoint}
                >
                  <p>{l.name}</p>
                  
                </Link>
              ))}
            </div>
          </div>
          <div className="bg-white xl:w-[840px] xl:h-[680px] xl:rounded-[20px]">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AccountLayout;
