import { BASE_URL } from "../constants/settings";
import { axiosHttp } from "../intercepters/axiosHandler";
import { saveAs } from "file-saver";

export const createOrder = async (payload) => {
  try {
    return Promise.resolve(
      await axiosHttp.post(`${BASE_URL}/api/v1/orders`, payload)
    );
  } catch (err) {
    Promise.reject(err);
  }
};

export const findOrderByID = async (orderID) => {
  return await axiosHttp.get(`${BASE_URL}/api/v1/orders/${orderID}`);
};

export const findOrders = async (payload) => {
  const { page, limit } = payload;
  try {
    return Promise.resolve(
      await axiosHttp.get(
        `${BASE_URL}/api/v1/orders?page=${page}&limit=${limit}`
      )
    );
  } catch (err) {
    Promise.reject(err);
  }
};

export const findSessionByID = async (payload) => {
  const { orderID, sessionID } = payload;

  try {
    return Promise.resolve(
      await axiosHttp.get(
        `${BASE_URL}/api/v1/orders/${orderID}/checkout-session/${sessionID}`
      )
    );
  } catch (error) {
    Promise.reject(error);
  }
};

export const findShippingCost = async () => {
  try {
    return Promise.resolve(
      await axiosHttp.get(`${BASE_URL}/api/v1/shipping-cost`)
    );
  } catch (error) {
    Promise.reject(error);
  }
};

export const findReceiptByPaymentID = async (paymentID) => {
  try {
    await axiosHttp
      .get(`${BASE_URL}/api/v1/payments/${paymentID}/receipt`, {
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });
        saveAs(blob);
      });
  } catch (error) {
    Promise.reject(error);
  }
};
