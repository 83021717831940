import React from "react";
import Logo from "../images/MealPrep.svg";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { setCurrentUser, signIn } from "../services/authService";
import { Cookies } from "react-cookie";
import { AuthState } from "../context/authContext";

const loginSchema = yup.object({
  email: yup
    .string()
    .email("Email format is not valid")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

const Login = () => {
  const navigate = useNavigate(); 
  const cookeis = new Cookies();
  const { setUser } = AuthState();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(loginSchema),
  });

  const createCokie = (cookieName, value) => {
    cookeis.set(cookieName, value, {
      sameSite: "none",
      path: "/",
      expires: new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
      // httpOnly: true,
    });
  };

  const onSubmit = async (data) => {
    try {
      const res = await signIn(data);

      if (res.status === 200) {
        // console.log(res.data.data);

        // TODO this cookie creation is a temp solution
        createCokie("mealprep-session", res.data.data.token);
        createCokie("mealprep-refresh", res.data.data.refreshToken);
        setCurrentUser(res.data.data.user);
        setUser(res.data.data.user);
        navigate("/");
      }
    } catch (e) {
      console.log(e);
      if (e.response.data.message) {
        setError("root", {
          message: e?.response?.data?.message,
        });
      }
    }
  };

  return (
    <div className="bg-white border rounded-[10px] xl:w-[490px]">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="flex justify-center mx-auto items-center mt-[70px] mb-[46.12px]">
          <img src={Logo} alt="Meal Prep" />
        </div>
        <div className="w-[400px] mx-auto">
          <label htmlFor="email" className="text-start">
            <p className="text-xs xl:mx-auto mx-8">Email Address</p>
          </label>
          <div className="form-control w-full my-3">
            <input
              className="border border-[#E3E3E3] rounded-lg text-[12px] xl:w-full w-5/6 h-[42px] px-4"
              placeholder="Email Address"
              type="email"
              {...register("email")}
            />
            {errors.email && (
              <div className="text-left text-xs text-red-500 my-2">
                {errors.email?.message}
              </div>
            )}
          </div>
          <label htmlFor="password" className="text-start">
            <p className="text-xs xl:mx-auto mx-8">Password</p>
          </label>
          <div className="form-control w-full my-3">
            <input
              className="border border-[#E3E3E3] rounded-lg text-xs xl:w-full w-5/6 h-[42px] px-4"
              placeholder="Password"
              type="password"
              {...register("password")}
            />
            {errors.password && (
              <div className="text-left text-xs text-red-500 my-2">
                {errors.password?.message}
              </div>
            )}
          </div>
          <button className="bg-black xl:w-full w-5/6 h-[36px] text-white rounded-[8px] text-xs mt-6">
            Login
          </button>
          {errors.root && (
            <div className="text-left text-xs text-red-500 my-2">
              {errors.root?.message}
            </div>
          )}
          <div className="flex justify-center mx-auto items-center mb-20">
            {/* <p className="text-xs my-3">Create an account</p> */}
            <button onClick={()=>navigate('/Steps')} className="border border-black w-5/6 xl:w-full h-[36px] text-black rounded-[8px] text-xs mt-6">
            Order now
          </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;