import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react.js";

import mbBox from "../../images/mealBigBox.svg";
import Circle from "../../images/Ellipse.svg";
import TwoBoxes from "../../images/mealPrepBoxes.svg";
import Plan from "../../images/Plan.svg";
import WeCnD from "../../images/Deliver.svg";
import HnE from "../../images/Enjoy.svg";
import ZeroC from "../../images/Commit.svg";

import Data from "../../Data.json";
import Menu from "../../Menu.json";

import Card1 from "../../components/Card1.js";
import BoxCard from "../../components/BoxCard.js";
import BoxCard2 from "../../components/BoxCard2.js";
import MenuCard from "../../components/MenuCard.js";
import Footer from "../../components/Footer.js";

import img1 from "../../images/image 7-1.png";
import { findMeals } from "../../services/mealService.jsx";
import { Button } from "@mui/material";
import OfferPopup from "../../components/offerForm.js";

const Home = (meal) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const [meals, setMeals] = useState([]);

  const [menuSliderRef, menuSliderInstance] = useKeenSlider({
    initial: 0,
    loop: true,
    slides: {
      number: meals.length,
      perView: 1,
      spacing: 16,
    },
  });

  const [reviewSliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    loop: true,
    breakpoints: {
      "(min-width: 300px)": {
        slides: { perView: 1, spacing: 16 },
      },
      "(min-width: 1000px)": {
        slides: { perView: 3, spacing: 16 },
      },
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  //AutoPlay Logic for qoute slider
  useEffect(() => {
    let timer;
    if (instanceRef.current) {
      timer = setInterval(() => {
        instanceRef.current?.next();
      }, 3000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [instanceRef]);

  //AutoPlay Logic for meal slider
  useEffect(() => {
    let menutimer;
    if (menuSliderInstance.current) {
      menutimer = setInterval(() => {
        menuSliderInstance.current?.next();
      }, 2800);
    }
    return () => {
      clearInterval(menutimer);
    };
  }, [menuSliderInstance]);

  const getMeals = async () => {
    const payload = {
      page: 1,
      limit: 8,
      keywords: "",
    };
    const res = await findMeals(payload);
    // console.log(res.data?.data?.list);
    setMeals(res.data?.data?.list);
  };

  useEffect(() => {
    getMeals();
  }, []);

  const [isGrabbing, setIsGrabbing] = useState(false);

  const handleMouseDown = () => {
    setIsGrabbing(true);
  };

  const handleMouseUp = () => {
    setIsGrabbing(false);
  };

  const navigate = useNavigate();

  return (
    <div className="mx-auto">
    <OfferPopup/>
      <div className="relative text-black h-[28rem] xl:h-[30rem] overflow-hidden">
        <picture>
          <img
            className="w-full h-full object-cover"
            src="/imgs/home_banner1.png"
            alt="Home-Banner"
          />
        </picture>

        <div className="absolute top-3/4 xl:left-1/2 xl:transform xl:-translate-x-1/2 -translate-y-1/2 text-left mx-4 xl:mx-0 flex flex-col justify-center items-center">
          <div className="absolute xl:bottom-40 bottom-56">
            <button
              onClick={() => navigate("/Steps")}
              className="text-white font-bold bg-redlight px-10 py-2 text-lg text-center justify-items-center rounded-lg animate-bounce hover:bg-white hover:text-black"
            >
              <p>Order Now</p>
            </button>
          </div>
          <div className="flex flex-col justify-center items-center">
            {/* <button onClick={()=>navigate('/Steps')} className="btn-white-xl text-center justify-items-center"><p>Order Now</p></button> */}
            <h1 className="text-white text-5xl font-bold text-left xl:w-[831px] w-[368px] mx-auto leading-[1.1]">
              Savory Cuisine, Ready in Minutes.
            </h1>
            <div className="mt-3">
              <p className="text-white xl:text-base text-sm xl:w-[410px] mx-auto">
                Fresh, Ready-Made Meals Delivered to Your Doorstep
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-20 text-center mx-4 xl:mx-0">
        <div className="text-black">
          <p className="text-[28px] xl:text-[42px] font-bold leading-none">
            Meals designed to preserve wellness
          </p>
        </div>
        <div className="mt-4 xl:mt-3">
          <p className="text-base text-center">
            Nourish Your Body, Nurture Your Goals
          </p>
        </div>
      </div>

      <div className="xl:flex justify-center items-center mt-12 mb-20 mx-4 xl:mx-0">
        <div className="grid grid-cols-2 xl:grid-cols-4 gap-3">
          <div>
            <Card1
              title="Choose Your Meals"
              imageUrl={Plan}
              imgH={"74px"}
              imgW={"74px"}
              body="Choose your preference from the extensive variety of options available on our menu."
            />
          </div>
          <div>
            <Card1
              title="We Cook & Deliver"
              imageUrl={WeCnD}
              imgH={"61px"}
              imgW={"102px"}
              body="Our skilled culinary chefs transform quality ingredients into delicious meals, promptly delivered to your doorstep."
            />
          </div>
          <div>
            <Card1
              title="Heat & Eat"
              imageUrl={HnE}
              imgH={"57px"}
              imgW={"61px"}
              body="Experience effortless dining with our prepped meals—simply heat up and savor in minutes, with no prep or mess involved."
            />
          </div>
          <div>
            <Card1
              title="Time-Saving Meals & Stress-Free Dining"
              imageUrl={ZeroC}
              imgH={"60px"}
              imgW={"38px"}
              body="Simplify your busy lifestyle by saving time on cooking. Our ready-to-eat meals make mealtime stress-free."
            />
          </div>
        </div>
      </div>

      {/* explore menu */}
      <div className="bg-creamWhite py-20">
        <div className="container mx-auto px-5">
          <div className="flex justify-center xl:justify-between mb-10">
            <p className="text-[28px] xl:text-5xl font-bold">
              Explore The Menu
            </p>
            <button
              onClick={() => navigate("/WeeklyMenu")}
              className="hidden xl:block btn-xl"
            >
              View All Meals
            </button>
          </div>

          {/* menu grid */}
          <div className="hidden xl:grid grid-cols-4 gap-4">
            {meals.map((data) => {
              return (
                <MenuCard key={data.id} imgUrl={data.imageUrl} data={data} />
              );
            })}
          </div>

          {/* menu slider */}
          <div className="navigation-wrapper mb-10 mx-4 xl:mx-0 block xl:hidden">
            <div ref={menuSliderRef} className="keen-slider">
              {/* slides */}
              {meals.length > 0
                ? meals.map((data) => (
                    <div
                      className="keen-slider__slide text-start"
                      key={data.id}
                    >
                      <MenuCard
                        key={data.id}
                        imgUrl={data.imageUrl}
                        data={data}
                      />
                    </div>
                  ))
                : null}
            </div>
          </div>
          <button
            onClick={() => navigate("/WeeklyMenu")}
            className="xl:hidden btn-outline-sm"
          >
            View All Meals
          </button>
        </div>
      </div>

      {/* loss weight & burn more fat */}
      <div className="overflow-x-hidden px-5">
        <div className="container mx-auto py-20 relative">
          <div className="xl:w-2/5 text-start mx-4 xl:mx-0">
            <img
              alt="mealBox"
              src={mbBox}
              className="xl:hidden overflow-hidden mb-10"
            />

            <p className="text-base xl:text-xl font-semibold">
              Empower your choices with Meal Prep!
            </p>
            {/* <p className="text-[28px] xl:text-5xl font-bold mt-3">
              Meal Solutions with <br />
              Meal Prep Boxes
            </p> */}
            <p className="text-[28px] xl:text-5xl font-bold mt-3">
              Make Your Own Plate <br />
            </p>
            <p className="text-base xl:text-lg mt-6 xl:mt-10">
              You have the power to craft your own personalized meals! Choose
              from a variety of options and tailor your dish by selecting
              proteins and carbs, all while keeping track of the nutritional
              details such as calories and fats.
            </p>
            <button
              onClick={() => navigate("/WeeklyMenu")}
              className="btn-xl mt-6 xl:mt-12"
            >
              View All Meals
            </button>
          </div>

          {/* desktop img */}
          <div className="hidden xl:block absolute top-1/2 -right-60 -translate-y-1/2">
            <img className="w-[850px] h-auto" src={mbBox} alt="mealBox" />
          </div>
        </div>
      </div>

      <div className="bg-neutral-50 py-5">
        {/* floating cards */}
        <div className="flex justify-center items-center py-5 xl:py-20 mx-4 lg:mx-0 px-5">
          <div className="flex flex-col xl:flex-row justify-center items-center gap-4">
            {/* top-content */}
            <div className="xl:hidden">
              <div className="grid grid-rows-2 grid-flow-col gap-4 xl:gap-5">
                <BoxCard
                  title="Diversity in Meal Choices"
                  msg="A diverse selection of meals to choose from. Ensuring deliciousness with every option."
                />
                <BoxCard
                  title="Easy Dining"
                  msg="Simplify your routine with easy dining — just heat and savor for a more convenient lifestyle."
                />
              </div>
            </div>
            {/* .. */}
            <div className="relative">
              {/* left-side */}
              <div className="hidden xl:block leftside absolute z-20 top-1/2 right-full -translate-y-1/2 mr-10">
                <div className="grid grid-rows-2 grid-flow-col gap-5">
                  <BoxCard
                    title="Diversity in Meal Choices"
                    msg="A diverse selection of meals to choose from. Ensuring deliciousness with every option."
                  />
                  <BoxCard
                    title="Easy Dining"
                    msg="Simplify your routine with easy dining — just heat and savor for a more convenient lifestyle."
                  />
                </div>
              </div>
              <img
                src={Circle}
                alt="ellipse"
                className="max-w-[247px] xl:max-w-[395px]"
              />

              <img
                alt="2Boxes"
                src={TwoBoxes}
                className="absolute z-10 top-1/2 left-1/2 transform translate-x-[-56%] -translate-y-1/2 min-w-[300px] xl:min-w-[700px]"
              />
              {/* right-side */}
              <div className="hidden xl:block rightside absolute z-20 top-1/2 left-full -translate-y-1/2 ml-10">
                <div className="grid grid-rows-2 grid-flow-col gap-5">
                  <BoxCard2
                    title="Tasty & Nutritious"
                    msg="Savor the excitement of our homestyle gourmet recipes, where healthy eating becomes a delightful and exciting experience."
                  />
                  <BoxCard2
                    title="Delectable Meals & Fast Delivery"
                    msg="Experience the swift delivery of delectable meals to your doorstep, ensuring lasting freshness in every bite."
                  />
                </div>
              </div>
            </div>
            {/* bottom-content */}
            <div className="xl:hidden">
              <div className="grid grid-rows-2 grid-flow-col gap-4 xl:gap-5">
                <BoxCard2
                  title="Tasty & Nutritious"
                  msg="Savor the excitement of our homestyle gourmet recipes, where healthy eating becomes a delightful and exciting experience."
                />
                <BoxCard2
                  title="Delectable Meals & Fast Delivery"
                  msg="Experience the swift delivery of delectable meals to your doorstep, ensuring lasting freshness in every bite."
                />
              </div>
            </div>
          </div>
        </div>
        <div className="xl:block hidden text-start px-5 xl:text-center mx-auto">
          <p className="font-bold text-4xl mx-4">
            Real People. Real Transformations.
          </p>
          <br />
          <p className=" mx-4">
            See how MealPrep customers have transformed their bodies and minds.
          </p>
        </div>
      </div>
      <div className="block xl:hidden text-start px-5 xl:text-center mx-auto py-20">
        <p className="font-bold text-4xl mx-4">Real People.</p>
        <p className="font-bold text-4xl mx-4">Real Transformations.</p>
        <br />
        <p className=" mx-4">
          See how MealPrep customers have transformed their bodies and minds.
        </p>
      </div>
      {/* reviews */}
      <div className="container mx-auto py-20 px-5">
        <div className="navigation-wrapper mb-10 mx-4 xl:mx-0">
          <div ref={reviewSliderRef} className="keen-slider">
            {/* slides */}
            {Data.map((result, index) => (
              <div
                className={`keen-slider__slide text-start ${
                  isGrabbing ? "cursor-grabbing" : "cursor-grab"
                }`}
                key={index}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
              >
                <p className="font-bold text-xl mb-4">{result.qtitle}</p>
                <p className="font-light">{result.quotes}</p>
              </div>
            ))}
          </div>
          {/* arrow navs */}
          {/* {loaded && instanceRef.current && (
            <>
              <Arrow
                left
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
                disabled={currentSlide === 0}
              />

              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
                disabled={
                  currentSlide ===
                  instanceRef.current.track.details.slides.length - 1
                }
              />
            </>
          )} */}
        </div>
        {loaded && instanceRef.current && (
          <div className="dots">
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  className={"dot" + (currentSlide === idx ? " active" : "")}
                ></button>
              );
            })}
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

// for slider navigations
//
// function Arrow(props) {
//   const disabled = props.disabled ? " arrow--disabled" : "";
//   return (
//     <svg
//       onClick={props.onClick}
//       className={`arrow ${
//         props.left ? "arrow--left" : "arrow--right"
//       } ${disabled}`}
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 24 24"
//     >
//       {props.left && (
//         <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
//       )}
//       {!props.left && (
//         <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
//       )}
//     </svg>
//   );
// }

export default Home;
