import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';
import Logout from './Logout';

const options = [
    { name: 'Profile', path: '/profile' },
    { name: 'Orders', path: '/orders' },
    { name: 'Logout', path: '/' } // Add the Logout option to the options array
];

const ITEM_HEIGHT = 48;

export default function LongMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option.name} onClick={() => {
                        if (option.name === 'Logout') {
                            // Invoke logout function directly here or via the Logout component's method
                            document.querySelector('.logout-button').click(); // If Logout renders a button with class 'logout-button'
                        } else {
                            handleClose();
                        }
                    }}>
                        {option.name !== 'Logout' ? <Link className="link" to={option.path}>{option.name}</Link> : 'Logout'}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}



//{options.map((option) => (
//    <MenuItem key={option.path} onClick={handleClose}>
//    {/* Render Logout component if the option name is 'Logout' */}
//    {option.name === 'Logout' ? <Logout /> : <Link className="link" to={option.path}>{option.name}</Link>}
//</MenuItem>
//))}