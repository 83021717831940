import React from "react";
import Banner from "../../images/faqBanner.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../components/Footer";

const Faqs = () => {
  return (
    <div>
      <div className="relative text-black h-[28rem] xl:h-[30rem] overflow-hidden">
        <picture>
          <img
            className="w-full h-full object-cover brightness-50"
            src={Banner}
            alt="HomeBanner"
          />
        </picture>

        <div className="absolute top-3/4 xl:left-1/2 xl:transform xl:-translate-x-1/2 -translate-y-1/2 text-left mx-4 xl:mx-0">
          <div className="">
            <h1 className="text-white text-[42px] font-bold text-left xl:w-[583px] w-[368px] mx-auto leading-[1.1]">
              Frequantly Asked Questions
            </h1>
          </div>
        </div>
      </div>

      <div className="text-start container mx-auto xl:mb-[100px] xl:mt-[71px] mt-[51px] mb-[51px]">
        <div className="xl:mb-[34px] mb-[14px] xl:mt-[29px]">
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              borderRadius: "15px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <p className="font-[700] xl:text-[24px] text-[14px]">
                1. What are the benefits of MealPrep?
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="xl:text-[20px] font-[400] text-[#6D6D6D]">
                The benefits of MealPrep are tailored to enhance your overall
                dining experience. Our dedicated team takes pride in preparing
                nutritious and flavorful meals that are delivered directly to
                your doorstep, saving you time and effort in meal preparation.
                With a wide variety of options to choose from, you can tailor
                your dish by selecting proteins and carbs while keeping track of
                important nutritional details such as calories and fats. This
                level of customization empowers you to take control of your
                nutrition and enjoy meals that are perfectly suited to your
                individual needs and busy lifestyle.
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="xl:mb-[34px] mb-[14px] xl:mt-[29px]">
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              borderRadius: "15px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <p className=" xl:text-[24px] text-[14px] font-[700]">
                2. How are the ingredients sourced?
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="xl:text-[20px] font-[400] text-[#6D6D6D]">
                Our dedication to sourcing fresh, locally sourced, and organic
                ingredients plays a significant role in the taste and
                healthiness of our meals. We pride ourselves on using only the
                freshest ingredients, carefully selected to ensure optimal
                flavor and nutrition in every dish we prepare. From vibrant
                fruits and vegetables to succulent meats and wholesome grains,
                our commitment to freshness shines through in every bite.
                Organic options provide added assurance that our meals are free
                from harmful pesticides and chemicals, offering a healthier and
                more wholesome dining experience. By carefully selecting our
                ingredients and ensuring that they meet our rigorous quality
                standards, we create meals that not only taste great but also
                support your overall health and well-being.
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="xl:mb-[34px] mb-[14px] xl:mt-[29px]">
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              borderRadius: "15px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <p className=" xl:text-[24px] text-[14px] font-[700]">
                3. How does Sultan Ahmet Meal Prep ensure food safety and
                quality?
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="xl:text-[20px] font-[400] text-[#6D6D6D]">
                <p>
                  We are committed to upholding the highest standards of food
                  safety and quality throughout our entire process. Our
                  dedicated team of chefs meticulously prepares each meal using
                  only the freshest and highest quality ingredients. We follow
                  strict sanitation and hygiene protocols in our kitchen
                  facilities to ensure cleanliness and prevent any risk of
                  contamination. Once prepared, our meals are carefully packaged
                  using industry-standard methods to maintain freshness and
                  preserve flavor. We utilize packaging materials that are both
                  safe and environmentally friendly, ensuring that your meals
                  arrive in perfect condition. When it comes to delivery, we
                  have established efficient and reliable methods to ensure that
                  your meals reach your doorstep promptly and safely. We
                  maintain optimal temperature control during transit to
                  preserve the freshness of the food.
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="xl:mb-[34px] mb-[14px] xl:mt-[29px]">
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              borderRadius: "15px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <p className=" xl:text-[24px] text-[14px] font-[700]">
                4. What types of meals are offered?
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="xl:text-[20px] font-[400] text-[#6D6D6D] ">
                Our menu features a delicious selection of Turkish cuisine,
                showcasing the rich flavors and traditional dishes of the
                region. In addition to Turkish favorites, we cater to various
                dietary preferences, ensuring there's something for everyone to
                enjoy. Whether you're craving classic comfort foods, exploring
                plant-based options, or following a specific dietary regimen,
                our menu has you covered. With a variety of proteins, carbs, and
                other nutritious ingredients to choose from, you can customize
                your meals to suit your taste and dietary needs.
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="xl:mb-[34px] mb-[14px] xl:mt-[29px]">
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              borderRadius: "15px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <p className=" xl:text-[24px] text-[14px] font-[700]">
                5. Are meals fresh or frozen?
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="xl:text-[20px] font-[400] text-[#6D6D6D] ">
                Our meals are always fresh and never frozen. Each meal is
                prepared with care using only high-quality ingredients, to
                ensure optimal taste and nutrition. Enjoy the convenience of
                receiving fresh meals delivered straight to your door, ready to
                savor without any compromise on quality or taste.
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="xl:mb-[34px] mb-[14px] xl:mt-[29px]">
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              borderRadius: "15px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <p className=" xl:text-[24px] text-[14px] font-[700]">
                6. What is the serving size of one meal?
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="xl:text-[20px] font-[400] text-[#6D6D6D] ">
                Each meal is portioned to serve one person, guaranteeing a
                satisfying and fulfilling dining experience with every order.
                Whether you're enjoying a hearty lunch or a satisfying dinner,
                our serving sizes are perfectly portioned to fuel your day and
                support your nutritional needs.
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="xl:mb-[34px] mb-[14px] xl:mt-[29px]">
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              borderRadius: "15px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <p className=" xl:text-[24px] text-[14px] font-[700]">
                7. How do the meals stay fresh during delivery?
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="xl:text-[20px] font-[400] text-[#6D6D6D]">
                Our meals are securely packed in boxes designed to withstand
                transit, ensuring their safety and freshness upon arrival. Once
                delivered to you, it's essential to store the meals in the
                refrigerator and consume them on or before the best-before date
                indicated on the box. This ensures that you enjoy the optimal
                taste and quality of each meal while maintaining food safety
                standards.
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="xl:mb-[34px] mb-[14px] xl:mt-[29px]">
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              borderRadius: "15px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <p className=" xl:text-[24px] text-[14px] font-[700]">
                8. How should I store my meals?
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="xl:text-[20px] font-[400] text-[#6D6D6D]">
                <p>
                  Upon delivery, simply unpack your meals and place them
                  directly into your refrigerator. It’s as simple as that.
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="xl:mb-[34px] mb-[14px] xl:mt-[29px]">
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              borderRadius: "15px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <p className=" xl:text-[24px] text-[14px] font-[700]">
                9. How can I heat the meals?
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="xl:text-[20px] font-[400] text-[#6D6D6D]">
                <p>
                  To heat the meals, you have the option of using either an oven
                  or a microwave. When using an oven, place the meal in an
                  oven-safe dish and heat until it reaches the desired
                  temperature. If opting for a microwave, heat on high for the
                  suggested time or until heated through. Regardless of the
                  method chosen, always ensure that the meal is thoroughly
                  heated before consuming.
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="xl:mb-[34px] mb-[14px] xl:mt-[29px]">
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              borderRadius: "15px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <p className=" xl:text-[24px] text-[14px] font-[700]">
                10. Can customers customize their meal plans?
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="xl:text-[20px] font-[400] text-[#6D6D6D]">
                <p>
                  We offer the unique advantage of customization, allowing you
                  to craft your personalized meals to suit your taste
                  preferences and dietary requirements. Whether you have
                  specific dietary restrictions or simply prefer certain
                  ingredients over others, our customizable meal options ensure
                  that you get exactly what you want.
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="xl:mb-[34px] mb-[14px] xl:mt-[29px]">
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              borderRadius: "15px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <p className=" xl:text-[24px] text-[14px] font-[700]">
                11. Can I cancel my subscription at any time?
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="xl:text-[20px] font-[400] text-[#6D6D6D]">
                <p>
                  Certainly! You have the flexibility to cancel your
                  subscription at any time without any hassle. Simply log into
                  your account, and you'll find the option to cancel your
                  subscription. It's important to note that while you can cancel
                  your subscription at any time, canceling it does not cancel
                  any existing orders. If you cancel your subscription before
                  our weekly cutoff time, no automated order will be placed for
                  delivery the following week. However, if you cancel after the
                  cutoff time and an automated order has already been placed,
                  that order cannot be canceled, and you will receive it the
                  following week as scheduled.
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="xl:mb-[34px] mb-[14px] xl:mt-[29px]">
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              borderRadius: "15px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <p className=" xl:text-[24px] text-[14px] font-[700]">
                12. How can customers provide feedback or get in touch for
                support?
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="xl:text-[20px] font-[400] text-[#6D6D6D]">
                <p>
                  Customers can contact us by email or via phone to get in touch
                  with a member of our customer service team. We value our
                  customers' input and are committed to providing excellent
                  support, so please don't hesitate to reach out to us with any
                  questions or concerns.
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Faqs;
