import React from "react";
import Login from "../../components/Login.js";
import Footer from "../../components/Footer.js";
import { AuthState } from "../../context/authContext.jsx";
import { Navigate } from "react-router-dom";

const login = () => {
  const { user } = AuthState();
  if (!!user) return <Navigate to={"/"} />;
  return (
    <>
      <div className="bg-creamWhite flex justify-center items-center h-screen">
        <Login />
      </div>
      <Footer />
    </>
  );
};

export default login;
