// import React, { useState, useEffect } from "react";

// const OfferPopup = () => {
//   const [showPopup, setShowPopup] = useState(false);

//   // Trigger the popup 5 seconds after page load
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setShowPopup(true);
//     }, 5000); // 5000ms = 5 seconds

//     // Clear the timer when the component unmounts
//     return () => clearTimeout(timer);
//   }, []);

//   const handleClose = () => {
//     setShowPopup(false); // Close the popup
//   };

//   return (
//     <>
//       {showPopup && (
//         <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full text-center">
//             <h2 className="text-2xl font-bold mb-4">Special Offer Just for You!</h2>
//             <p className="text-lg mb-6">Get 20% off on your first purchase. Use code: WELCOME20 at checkout.</p>
//             <button
//               className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition"
//               onClick={handleClose}
//             >
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default OfferPopup;


import React, { useState, useEffect } from "react";
import {Link, useNavigate} from "react-router-dom";
import { useKeenSlider } from "keen-slider/react.js";
import MenuCard from "./MenuCard";
import { findMeals } from "../services/mealService";
import offer from "../images/offer.svg";


const OfferPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  // Trigger the popup 5 seconds after page load
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000); // 5000ms = 5 seconds

    // Clear the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setShowPopup(false); // Close the popup
  };

  const navigate = useNavigate();

  const handleOrder = () => {
    navigate("/steps")
  }

  const [meals, setMeals] = useState([]);

  const [menuSliderRef, menuSliderInstance] = useKeenSlider({
    initial: 0,
    loop: true,
    slides: {
      number: meals.length,
      perView: 1,
      spacing: 16,
    },
  });

  const getMeals = async () => {
    const payload = {
      page: 1,
      limit: 8,
      keywords: "",
    };
    const res = await findMeals(payload);
    // console.log(res.data?.data?.list);
    setMeals(res.data?.data?.list);
  };

  useEffect(() => {
    getMeals();
  }, []);

  return (
    <>
      {showPopup && (
        <div className="fixed bottom-72 right-6 bg-white p-4 rounded-lg shadow-lg w-80 z-50 border border-gray-300">
          <div className="relative">
            {/* Close Button */}
            <button
              className="absolute top-0 right-0 text-gray-400 hover:text-gray-600"
              onClick={handleClose}
            >
              &times; {/* Cross 'x' icon */}
            </button>

            {/* Content */}
            <img src={offer} alt="%"/>
            <h2 className="text-xl font-bold mb-2">Special Offer Just for You!</h2>
            
            <div className="navigation-wrapper mb-10 mx-4 xl:mx-0 block xl:hidden">
            
            {/* <div ref={menuSliderRef} className="keen-slider">
              
              {meals.length > 0
                ? meals.map((data) => (
                    <div
                      className="keen-slider__slide text-start"
                      key={data.id}
                    >
                      <MenuCard
                        key={data.id}
                        imgUrl={data.imageUrl}
                        data={data}
                      />
                    </div>
                  ))
                : null}
            </div> */}
            
          </div>
            {/* <p className="text-sm mb-4">Get 20% off on your first purchase. Use code: <strong>WELCOME20</strong> at checkout.</p> */}
            
            {/* Optional CTA Button */}
            <button
              className="bg-redlight text-white px-4 py-2 rounded transition w-full"
              onClick={handleOrder}
            >
              Order Now
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default OfferPopup;
