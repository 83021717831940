import React from "react";

const Card1 = ({ title, imageUrl, body, imgW, imgH }) => {
  return (
    <div className="xl:w-[300px] h-[160px] xl:h-[220px] bg-gray-100 rounded-xl flex flex-col justify-between p-4 xl:p-7">
      <div className="flex xl:justify-center justify-start">
        <img
          src={imageUrl}
          alt="BoxImg"
          className="max-h-[46px] xl:max-h-[74px] max-w-[65px] xl:max-w-[102px]"
        />
      </div>
      <div className="flex flex-col gap-1 xl:gap-2">
        <p className="font-semibold text-left xl:text-center text-sm xl:text-lg">
          {title}
        </p>
        <p className="text-[#777] text-left xl:text-center text-[8px] xl:text-xs">
          {body}
        </p>
      </div>
    </div>
  );
};

export default Card1;
