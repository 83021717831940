import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { findCategories } from "../services/categoryService";
import { findPlans } from "../services/planService";
import { findShippingCost } from "../services/orderService";

const planSchema = yup.object({
  categoryIds: yup
    .array()
    .min(1, "Select at least one category")
    .required("Select at least one category"),
  planId: yup.string().required(),
  // shippingAmount: yup.number().required(),
  coupon: yup.string(),
});

const SelectPlan = ({ setCurrentStep, payload, setPayload, user }) => {
  const [categories, setCategories] = useState([]);
  const [plans, setPlans] = useState([]);
  const [shippingCost, setShippingCost] = useState(0);

  const [formData, setFormData] = useState({
    categoryIds: ["63ff72bf-a970-45d2-bacf-1f2e61cd5332"],
    planId: "52e43a11-9784-4cb0-a308-f5a27146204c",
    // shippingAmount: 0,
    coupon: "",
  });
  const [errors, setErrors] = useState({});

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    let updatedCategoryIds = [...formData.categoryIds];

    if (checked) {
      updatedCategoryIds.push(name);
    } else {
      updatedCategoryIds = updatedCategoryIds.filter((c) => c !== name);
    }

    setFormData({
      ...formData,
      categoryIds: updatedCategoryIds,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await planSchema.validate(formData, { abortEarly: false });
      setErrors({});
      if (!!user) {
        setPayload({ ...payload, ...formData, userId: user.id });
        setCurrentStep((prev) => prev + 2);
      } else {
        setPayload({ ...payload, ...formData });
        setCurrentStep((prev) => prev + 1);
      }
    } catch (error) {
      // console.log(err.inner);
      const newErrors = {};

      error.inner.forEach((err) => {
        newErrors[err.path] = err.message;
      });

      setErrors(newErrors);
    }
  };

  const getShippingCost = async () => {
    const res = await findShippingCost();
    // console.log(res.data.data.amount);
    setShippingCost(res.data.data.amount);
  };

  const getCategories = async () => {
    const res = await findCategories();
    // console.log(res.data.data.list);
    setCategories(res.data.data.list);
  };

  const getPlans = async () => {
    const res = await findPlans();
    // console.log(res.data.data.list);
    setPlans(res.data.data.list);
  };

  useEffect(() => {
    getCategories();
    getPlans();
    getShippingCost();
  }, []);

  const setCategoryNameFromID = (id) => {
    const selected = categories?.find((c) => c.id === id);
    // console.log(selected);
    return selected?.name;
  };

  const setPlanFromID = (id) => {
    const selected = plans?.find((p) => p.id === id);
    // console.log(selected);
    return selected?.name;
  };

  const setPlanPriceFromID = (id) => {
    const selected = plans?.find((p) => p.id === id);
    return selected?.planPrice?.amount;
  };

  const setTotalPriceWithPlanID = (planID, shippingAmount) => {
    const selected = plans?.find((p) => p.id === planID);
    const total = Number(selected?.planPrice?.amount) + Number(shippingAmount);

    return total.toFixed(2) || 0;
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="text-start pb-7 border-b">
          <p className="xl:text-2xl font-bold mb-2">Choose your plan size</p>
          <p>
            We'll use this as your default plan size, but you can customise it
            from week to week.
          </p>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-2">
          <div className="xl:m-8">
            {/* select meal preference */}
            <p className="text-start xl:text-lg font-bold mb-3">
              Choose your preferences
            </p>
            <div className="formControl-checkbox mb-6">
              <div className="grid grid-cols-2 gap-3 h-52 overflow-auto">
                {categories.map((c) => (
                  <label
                    key={c.id}
                    className={`flex justify-center items-center border rounded-lg cursor-pointer p-8 text-center text-sm xl:text-base font-medium select-none ${
                      formData.categoryIds.includes(c.id)
                        ? "bg-ClickRed text-white"
                        : "bg-white"
                    }`}
                  >
                    <input
                      type="checkbox"
                      name={c.id}
                      checked={formData.categoryIds.includes(c.id)}
                      onChange={handleCheckboxChange}
                    />
                    {c.name}
                  </label>
                ))}
              </div>
              {!!errors.categoryIds && (
                <div className="text-sm text-red-500 text-left my-2">
                  {errors?.categoryIds}
                </div>
              )}
            </div>
            {/* select recipes per week */}
            <p className="text-start xl:text-lg font-bold mb-3">
              Recipes per week
            </p>
            <div className="formControl-radiobutton grid grid-cols-3 gap-3 mb-6">
              {plans.map((p) => (
                <label
                  key={p.id}
                  className={`flex justify-center items-center border rounded-lg cursor-pointer p-4 text-sm xl:text-base text-center font-medium select-none ${
                    formData.planId === p.id
                      ? "bg-ClickRed text-white"
                      : "bg-white"
                  }`}
                >
                  <input
                    type="radio"
                    name="planId"
                    value={p.id}
                    checked={formData.planId === p.id}
                    onChange={handleFormDataChange}
                  />
                  {p.name}
                </label>
              ))}
            </div>
          </div>
          <div className="xl:border-l">
            <div className="xl:m-8">
              <p className="text-start xl:text-lg font-bold mb-3">
                Price summary
              </p>
              <div className="xl:px-2">
                <div>
                  {formData.categoryIds.length > 0 && (
                    <p className="text-start mt-3 font-normal">
                      {formData.categoryIds.length === 1
                        ? setCategoryNameFromID(formData.categoryIds[0])
                        : "Multi-select"}
                    </p>
                  )}
                  {formData.planId && (
                    <p className="text-start mt-3 font-normal">
                      {setPlanFromID(formData.planId)} Meals per week
                    </p>
                  )}
                </div>
                {/* Price summary details */}
                <div className="flex flex-col gap-2 mt-10 xl:mr-20">
                  <div className="flex justify-between">
                    <p>Box Price</p>
                    <p>{"$" + setPlanPriceFromID(formData.planId)}</p>
                  </div>
                  {/* <div className="flex justify-between">
                    <p>Price per serving</p>
                    <p>$11.99</p>
                  </div> */}
                  <div className="flex justify-between">
                    <p>Shipping</p>
                    <p>{"$" + shippingCost.toFixed(2)}</p>
                  </div>
                </div>
              </div>
              {/* total */}
              <div className="flex justify-between items-center bg-psumGray border rounded-lg px-2 py-3 my-2 xl:mr-20">
                <p className="font-bold">Total</p>
                <p className="font-bold">
                  {setTotalPriceWithPlanID(formData.planId, shippingCost)}
                </p>
              </div>
              <div className="flex flex-col gap-3 mt-10 xl:mr-20">
                <TextField
                  className="h-12 rounded-2xl"
                  label="Coupon Code"
                  variant="outlined"
                  name="coupon"
                  onChange={handleFormDataChange}
                />
                <button
                  className="bg-black text-white rounded-md h-12 my-3 disabled:cursor-not-allowed disabled:bg-black/80"
                  disabled={formData.categoryIds.length < 1}
                >
                  Select Plan
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default SelectPlan;
