import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { getCurrentUser } from "../services/authService";
import { useEffect, useState } from "react";
import { findOneUser, updateUser } from "../services/userServices";

const profileSchema = yup.object({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  mobileNumber: yup.string(),
  profile: yup.object({
    addressLine1: yup.string().required("Address Line 1 is required"),
    addressLine2: yup.string().required("Address Line 2 is required"),
    city: yup.string().required("City is required"),
    province: yup.string().required("Province is required"),
    postalCode: yup.string().required("Postal Code is required"),
    deliveryNote: yup.string(),
  }),
});

const EditProfile = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(profileSchema),
  });
  const currentUser = getCurrentUser();
  const [userData, setUserData] = useState();

  const onSubmit = (formData) => {
    updateUserByID(formData, userData.id);
  };

  const getOneUser = async () => {
    try {
      const res = await findOneUser(currentUser?.id);
      const userInfo = res.data.data;
      // console.log(userInfo);
      setUserData(userInfo);
      //   form population
      setValue("firstName", userInfo?.firstName);
      setValue("lastName", userInfo?.lastName);
      setValue("mobileNumber", userInfo?.mobileNumber);
      setValue("profile.addressLine1", userInfo?.profile?.addressLine1);
      setValue("profile.addressLine2", userInfo?.profile?.addressLine2);
      setValue("profile.city", userInfo?.profile?.city);
      setValue("profile.province", userInfo?.profile?.province);
      setValue("profile.postalCode", userInfo?.profile?.postalCode);
      setValue("profile.deliveryNote", userInfo?.profile?.deliveryNote);
      //
    } catch (error) {
      console.log(error);
    }
  };

  const updateUserByID = async (data, userID) => {
    try {
      await updateUser(data, userID);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentUser.id) {
      getOneUser();
    }
  }, []);

  return (
    <>
      <div className="m-8">
        <div className="text-start ">
          <p className="text-[24px] font-bold text-start ">Profile Info</p>
        </div>
        <hr className="border-[#D7D7D7] border-[1px] my-8 " />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="my-10">
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-3">
              <div className="form-control w-full">
                <input
                  type="text"
                  className="border border-[#E3E3E3] rounded-lg text-xs  w-full h-[42px] px-4"
                  {...register("firstName")}
                  placeholder="First Name"
                />
                {errors.firstName && (
                  <div className="text-left text-xs  text-red-500 my-2">
                    {errors.firstName?.message}
                  </div>
                )}
              </div>
              <div className="form-control w-full">
                <input
                  type="text"
                  className="border border-[#E3E3E3] rounded-lg text-xs  w-full h-[42px] px-4"
                  {...register("lastName")}
                  placeholder="Last Name"
                />
                {errors.lastName && (
                  <div className="text-left text-xs  text-red-500 my-2">
                    {errors.lastName?.message}
                  </div>
                )}
              </div>
              <div className="form-control w-full">
                <input
                  type="text"
                  className="border border-[#E3E3E3] rounded-lg text-xs  w-full h-[42px] px-4"
                  {...register("mobileNumber")}
                  placeholder="Phone Number"
                />
                {errors.mobileNumber && (
                  <div className="text-left text-xs  text-red-500 my-2">
                    {errors.mobileNumber?.message}
                  </div>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 xl:grid-cols-2 gap-3 mt-6">
              <div className="form-control w-full">
                <input
                  type="text"
                  className="border border-[#E3E3E3] rounded-lg text-xs  w-full h-[42px] px-4"
                  {...register("profile.addressLine1")}
                  placeholder="Address Line 1"
                />
                {errors.profile?.addressLine1 && (
                  <div className="text-left text-xs  text-red-500 my-2">
                    {errors.profile?.addressLine1?.message}
                  </div>
                )}
              </div>
              <div className="form-control w-full">
                <input
                  type="text"
                  className="border border-[#E3E3E3] rounded-lg text-xs  w-full h-[42px] px-4"
                  {...register("profile.addressLine2")}
                  placeholder="Address Line 2"
                />
                {errors.profile?.addressLine2 && (
                  <div className="text-left text-xs  text-red-500 my-2">
                    {errors.profile?.addressLine2?.message}
                  </div>
                )}
              </div>
              <div className="form-control w-full">
                <input
                  type="text"
                  className="border border-[#E3E3E3] rounded-lg text-xs  w-full h-[42px] px-4"
                  {...register("profile.city")}
                  placeholder="City"
                />
                {errors.profile?.city && (
                  <div className="text-left text-xs  text-red-500 my-2">
                    {errors.profile?.city?.message}
                  </div>
                )}
              </div>
              {/* <div className="form-control w-full block xl:hidden">
                  <input
                    type="text"
                    className="border border-[#E3E3E3] rounded-lg text-xs  w-full h-[42px] px-4"
                    {...register("profile.postalCode")}
                    placeholder="Postal"
                  />
                  {errors.profile?.postalCode && (
                    <div className="text-left text-xs  text-red-500 my-2">
                      {errors.profile?.postalCode?.message}
                    </div>
                  )}
                </div> */}
              <div className="grid grid-cols-2 gap-3">
                <div className="form-control w-full">
                  <input
                    type="text"
                    className="border border-[#E3E3E3] rounded-lg text-xs  w-full h-[42px] px-4"
                    {...register("profile.province")}
                    placeholder="Province"
                  />
                  {errors.profile?.province && (
                    <div className="text-left text-xs  text-red-500 my-2">
                      {errors.profile?.province?.message}
                    </div>
                  )}
                </div>
                <div className="form-control w-full">
                  <input
                    type="text"
                    className="border border-[#E3E3E3] rounded-lg text-xs  w-full h-[42px] px-4"
                    {...register("profile.postalCode")}
                    placeholder="Postal"
                  />
                  {errors.profile?.postalCode && (
                    <div className="text-left text-xs  text-red-500 my-2">
                      {errors.profile?.postalCode?.message}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="text-start mt-6">
              <p className="text-sm mb-3">Special Instructions</p>

              <div className="xl:w-1/2">
                <div className="form-control w-full">
                  <input
                    type="text"
                    className="border border-[#E3E3E3] rounded-lg text-xs  w-full h-[42px] px-4"
                    {...register("profile.deliveryNote")}
                    placeholder="Leave at Door"
                  />
                  {errors.profile?.deliveryNote && (
                    <div className="text-left text-xs  text-red-500 my-2">
                      {errors.profile?.deliveryNote?.message}
                    </div>
                  )}
                </div>
                <button className="bg-black w-full h-[36px] text-white rounded-[8px] text-xs  mt-6">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditProfile;
