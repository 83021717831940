import React from "react";

const BoxCard2 = ({ title, msg }) => {
  return (
    <div className="bg-gray-100 xl:bg-gradient-to-r from-gray-100 to-white w-[330px] rounded-2xl p-6">
      <div className="font-bold text-center xl:text-left xl:text-xl">{title}</div>
      <div className="text-center xl:text-left text-sm">{msg}</div>
    </div>
  );
};

export default BoxCard2;
