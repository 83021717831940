import axios from "axios";
import { BASE_URL } from "../constants/settings";

export const findCategories = async () => {
  try {
    return Promise.resolve(await axios.get(`${BASE_URL}/api/v1/categories`));
  } catch (err) {
    Promise.reject(err);
  }
};
