import { BASE_URL } from "../constants/settings";
import { axiosHttp } from "../intercepters/axiosHandler";

export const validateEmail = async (payload) => {
  try {
    return Promise.resolve(
      await axiosHttp.post(`${BASE_URL}/api/v1/auth/validate-email`, payload)
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const signup = async (payload) => {
  try {
    return Promise.resolve(
      await axiosHttp.post(`${BASE_URL}/api/v1/auth/signup`, payload)
    );
  } catch (err) {
    return Promise.reject(err);
  }
};

export const signIn = async (payload) => {
  try {
    return Promise.resolve(
      await axiosHttp.post(`${BASE_URL}/api/v1/auth/signin`, payload)
    );
  } catch (err) {
    return Promise.reject(err);
  }
};

export const refreshToken = async () => {
  try {
    return Promise.resolve(
      await axiosHttp.post(`${BASE_URL}/api/v1/auth/refresh-token`)
    );
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("LOGGEDIN_USER")) || null;
};

export const setCurrentUser = (userInfo) => {
  localStorage.setItem("LOGGEDIN_USER", JSON.stringify(userInfo));
};

export const removeCurrentUser = () => {
  localStorage.removeItem("LOGGEDIN_USER");
};