import ReactPaginate from "react-paginate";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

function PaginationButtons({ totalPages, currentPage, setCurrentPage }) {
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <>
      <ReactPaginate
        breakLabel={<span className="mx-2">...</span>}
        nextLabel={
          <span className="size-10 flex items-center justify-center bg-gray-200 rounded-md">
            <BsChevronRight />
          </span>
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={totalPages}
        previousLabel={
          <span className="size-10 flex items-center justify-center bg-gray-200 rounded-md disabled:bg-black">
            <BsChevronLeft />
          </span>
        }
        containerClassName="flex items-center justify-center gap-2 mt-8 mb-4"
        pageLinkClassName="block hover:bg-gray-200 size-10 flex justify-center items-center rounded-md duration-100"
        activeLinkClassName="bg-[#E30032] text-white hover:text-black duration-100"
      />
    </>
  );
}

export default PaginationButtons;
