import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./pages/home/home";
import Nav from "./components/NavBar";
import Test from "./pages/home/test";
import WeeklyMenu from "./pages/other/weeklyMenu";
import AboutUs from "./pages/other/aboutUs";
import MultiStep from "./pages/other/multiStep";
import MealDetails from "./pages/other/mealDetails";
import ScrollTop from "./components/ScrollTop";
import FAQS from "./pages/other/faqs";
import Login from "./pages/login/login";
import MyOrders from "./pages/other/myOrders";
import AuthProvider from "./context/authContext";
import PrivateRoute from "./utils/privateRoute";
import PageNotFound from "./components/PageNotFound";
import CheckOut from "./pages/checkOut";
import AccountLayout from "./layouts/account-layout";
import WithOrders from "./components/WithOrders";
import EditProfile from "./components/EditProfile";
import Terms from "./pages/other/terms";

// import SelectPlan from './components/SelectPlan';
// import Register from './components/Register';
// import Address from './components/Address';
// import Checkout from './components/Checkout';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <Nav />
          <ScrollTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/test" element={<Test />} />
            <Route
              path="/weeklyMenu"
              element={<Navigate to={"/weeklyMenu/0"} />}
            />
            <Route path="/weeklyMenu/:weekID" element={<WeeklyMenu />} />
            <Route path="/steps" element={<MultiStep />} />
            <Route path="/meals/:mealID" element={<MealDetails />} />
            <Route path="/faqs" element={<FAQS />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/terms&conditions" element={<Terms/>} />

            {/* Non Members only */}
            <Route exact path="/login" element={<Login />} />

            {/* Member only - Protected Routes */}
            <Route element={<PrivateRoute />}>
              <Route
                path="/checkout/:orderID?/:sessionID?"
                element={<CheckOut />}
              />

              <Route element={<AccountLayout />}>
                <Route path="/orders" element={<WithOrders />} />
                <Route path="/profile" element={<EditProfile />} />
              </Route>
            </Route>

            <Route path="*" element={<PageNotFound />} />

            {/* <Route Component={PageNotFound}/> */}
          </Routes>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
