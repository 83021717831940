import React, { useEffect, useState } from "react";
import OrderCard from "./OrderCard";
import { getCurrentUser } from "../services/authService";
import { findOrders } from "../services/orderService";
import PaginationButtons from "../utils/paginationButtons";

const WithOrders = () => {
  const [orders, setOrders] = useState([]);

  const [ordersCount, setOrdersCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 2;
  const totalPages = Math.ceil(ordersCount / itemsPerPage) || 1;

  const getOrdersByUserID = async () => {
    try {
      const payload = {
        page: Math.min(currentPage + 1, totalPages),
        limit: itemsPerPage,
      };
      const res = await findOrders(payload);
      // console.log(res.data.data.list);
      setOrders(res.data.data.list);
      setOrdersCount(res.data.data.count);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrdersByUserID();
  }, [currentPage]);

  return (
    <>
      <div className="m-8 xl:bg-white ">
        <div className="text-start ">
          <p className="text-[24px] font-bold text-start ">My Orders</p>
        </div>
        <hr className="border-[#D7D7D7] border-[1px] my-8 " />

        <div className="">
          <div className="">
            {orders.length > 0 ? (
              <>
                <div className="space-y-3 min-h-[416px]">
                  {orders.map((o) => (
                    <OrderCard key={o.id} order={o} />
                  ))}
                </div>
                <PaginationButtons
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </>
            ) : (
              <>
                <div className="flex justify-center items-center border-[1px] border-[#EEDAA8] bg-[#FFF6E3] rounded-[10px] h-[60px]">
                  <p className="text-[#EBB346] font-medium">
                    No order has been made yet.
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WithOrders;
