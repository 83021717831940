import React from "react";
import Footer from "../../components/Footer";
import StepForm from "../../components/StepForm";

const MultiStep = () => {
  return (
    <>
      <div className="bg-creamWhite py-20">
        <div className="container mx-auto">
          {/* <SelectPlan /> */}
          <StepForm/>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MultiStep;