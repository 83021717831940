import React from "react";
import Done from "../images/psucss.gif";

const Payment = ({ order, user, donwnloadReceipt }) => {
  return (
    <div className="bg-white mx-auto w-1/3 rounded-2xl px-8 py-10">
      <div className="flex flex-col items-center gap-2">
        <img
          src={Done}
          alt="Payment Success GIF"
          className="block size-[70px]"
        />
        <p className="text-2xl font-bold">Payment Successful!</p>
      </div>
      <div className="text-start mt-8">
        <div className="border-b">
          <p className="font-bold mb-4">Summary</p>
        </div>
        <div className="grid grid-cols-2 my-4">
          <div className="text-start space-y-3">
            <p>Sub Total</p>
            <p>Shipping Fee</p>
            <p>Estimated Tax</p>
          </div>
          <div className="text-end font-bold space-y-3">
            <p>${order?.planAmount}</p>
            <p>${order?.shippingAmount}</p>
            <p>$0.00</p>
          </div>
        </div>
        <div className="border-t flex justify-between pt-4">
          <p className="font-bold">TOTAL</p>
          <p className="font-bold">${order?.totalAmount}</p>
        </div>
      </div>

      <div className="text-start mt-8">
        <div className="border-b">
          <p className="font-bold mb-4">Shipping Details</p>
        </div>
        <div className="grid grid-cols-2 my-4">
          <div className="text-start space-y-3">
            <p>Contact Name</p>
            <p>Mobile No</p>
            <p>Address</p>
          </div>
          <div className="text-end font-bold space-y-3">
            <p>{user?.firstName + " " + user?.lastName}</p>
            <p>{user?.mobileNumber}</p>
            <p>
              {user?.profile.addressLine1}, {user?.profile.addressLine2},{" "}
              {user?.profile.city}, {user?.profile.province},{" "}
              {user?.profile.postalCode}
            </p>
          </div>
        </div>
      </div>
      <button
        className="mt-20 w-full h-[44px] bg-black text-white rounded-[8px]"
        onClick={donwnloadReceipt}
      >
        Download Receipt
      </button>
    </div>
  );
};

export default Payment;
