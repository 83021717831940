import React, { useEffect, useState } from "react";
import img1 from "../../images/image 14.png";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../components/Footer.js";
import { useNavigate, useParams } from "react-router-dom";
import { findMealByID } from "../../services/mealService.jsx";

const MealDetails = () => {
  const { mealID } = useParams();
  const navigate = useNavigate();

  const [meal, setMeal] = useState({});

  const getMealInfoByID = () => {
    findMealByID(mealID)
      .then((res) => {
        if (res.data.status === 200) {
          // console.log(res.data.data);
          setMeal(res.data.data);
        } else {
          console.log(res.data);
          navigate(-1);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getMealInfoByID();
  }, [mealID]);

  return (
    <>
      <div className="container mx-auto xl:py-20 px-5">
        <div className="xl:grid xl:grid-cols-[538px_minmax(900px,_1fr)_79px]">
          <div className=" xl:m-0 m-[10px]">
            <img src={meal.imageUrl} alt="meal" className="w-[full] xl:w-[484px]"/>
          </div>

          <div className="text-start xl:m-0 m-[10px]">
            <p className="xl:text-[36px] text-[26px] font-[600]">{meal.name}</p>

            <p className="text-[16px] font-[400] xl:mt-[17px] text-[#1E1E1E] xl:mb-[21px] mt-[7px] mb-[11px] ">
              {meal.description}
            </p>

            <div className="flex items-start space-x-1 xl:mb-0 mb-2">
              {meal.categories &&
                meal?.categories.map((c) => (
                  <div
                    key={c.id}
                    className="bg-FillIng2 text-OrangeIng2 rounded-full text-center py-1 px-3"
                  >
                    <p className="text-xs">{c.name}</p>
                  </div>
                ))}
            </div>

            <p className="xl:mt-[47px] xl:text-[24px] font-[700]">
              {"$" + meal?.price}
            </p>
            <p className="xl:text-[16px] font-[500] xl:mb-[53px] mb-[43px]">
              Potion Size - {(meal?.posionSize || "0.00") + "g"}
            </p>

            <hr className="border-[1px] bg-[#D8D8D8] " />

            <div className="xl:mb-[34px]  xl:mt-[29px] mb-[24px]">
              <Accordion
                sx={{
                  boxShadow: 'none',
                }}
                defaultExpanded={true}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <p className="font-[700] xl:text-[22px]">Ingredients</p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="text-[14px] font-[400] text-[#1E1E1E]">
                    {meal?.ingredients}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <hr className="border-[1px] bg-[#D8D8D8]" />
            {/* //? can't fully integrate yet */}

            <div className="hidden mb-[34px] xl:mt-[29px]">
              <Accordion
                sx={{
                  boxShadow: 'none',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <p className=" xl:text-[22px] font-[700]">Nutrition</p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="xl:text-[14px] text-[13px] font-[400] text-[#1E1E1E] grid grid-cols-3 gap-2">
                    <div className="space-y-[30px]">
                      <p className="mb-[15px] font-bold">1 Serving</p>
                      {/* //todo - intigrate */}
                      <p>Energy</p>
                      <p>Calories</p>
                      <p>Protein</p>
                      <p>Fat, Total</p>
                      <p>Saturated</p>
                      <p>Carbohydrates</p>
                      <p>Sugars</p>
                      <p>Dietary Fibre</p>
                      <p>Sodium</p>
                    </div>
                    <div className="space-y-[30px] text-right">
                      <p className="mb-[15px] font-bold">per serving (345g)</p>
                      <p>1610kJ</p>
                      <p>384Cal</p>
                      <p>36.2g</p>
                      <p>2.7g</p>
                      <p>1.1g</p>
                      <p>51g</p>
                      <p>23g</p>
                      <p>3.4g</p>
                      <p>1360mg</p>
                    </div>
                    <div className="space-y-[30px] text-right">
                      <p className="mb-[15px] font-bold">per 100g</p>
                      <p>467kJ</p>
                      <p>111Cal</p>
                      <p>10.5g</p>
                      <p>0.8g</p>
                      <p>0.3g</p>
                      <p>14.8g</p>
                      <p>6.7g</p>
                      <p>1g</p>
                      <p>394mg</p>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <hr className="border-[1px] bg-[#D8D8D8]" />
            {/* //? can't fully integrate yet */}

            <div className="mb-[34px] xl:mt-[29px]">
              <Accordion
                sx={{
                  boxShadow: 'none',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <p className=" xl:text-[22px] font-[700]">Instructions</p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="text-[14px] font-[400] text-[#1E1E1E]">
                    <p className="font-bold">Storage:</p>
                    <p>{meal?.storageInstructions}</p>
                    <br />
                    <p className="font-bold">Heating from fresh:</p>
                    <p>{meal?.heatingFromFreshInstructions}</p>
                    <br />
                    <p className="font-bold">Heating from frozen:</p>
                    <p>{meal?.heatingFromFrozenInstructions}</p>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <hr className="border-[1px] bg-[#D8D8D8]" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MealDetails;
