import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { updateUser } from "../services/userServices";
import { setCurrentUser, signup } from "../services/authService";
import { Cookies } from "react-cookie";
import { AuthState } from "../context/authContext";
import { REQUEST_TYPES } from "../constants/settings";

const addressSchema = yup.object({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  mobileNumber: yup
    .string()
    .required("Mobile Number is required")
    .matches(/^[0-9]+$/, "Mobile number is not valid")
    .length(10, "Mobile number must be 10 numbers"),
  profile: yup.object({
    addressLine1: yup.string().required("Address Line 1 is required"),
    addressLine2: yup.string().required("Address Line 2 is required"),
    city: yup.string().required("City is required"),
    province: yup.string().required("Province is required"),
    postalCode: yup.string().required("Postal Code is required"),
    deliveryNote: yup.string(),
  }),
});

const Address = ({
  setCurrentStep,
  payload,
  setPayload,
  registerForm,
  userInfo,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addressSchema),
  });
  const { setUser } = AuthState();

  const cookeis = new Cookies();
  const createCookie = (cookieName, value) => {
    cookeis.set(cookieName, value, {
      sameSite: "none",
      path: "/",
      expires: new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
      // httpOnly: true,
    });
  };

  const populateDeliveryForm = () => {
    setValue("firstName", userInfo?.firstName);
    setValue("lastName", userInfo?.lastName);
    setValue("mobileNumber", userInfo?.mobileNumber);
    setValue("profile.addressLine1", userInfo?.profile?.addressLine1);
    setValue("profile.addressLine2", userInfo?.profile?.addressLine2);
    setValue("profile.city", userInfo?.profile?.city);
    setValue("profile.province", userInfo?.profile?.province);
    setValue("profile.postalCode", userInfo?.profile?.postalCode);
    setValue("profile.deliveryNote", userInfo?.profile?.deliveryNote);
  };

  // need to write a function when user is logged in get user location and when got logged in to register

  const onCreateUser = async (data) => {
    try {
      const formData = {
        ...registerForm,
        ...data,
      };
      console.log(formData);

      const res = await signup(formData);
      console.log(res);

      // TODO this cookie creation is a temp solution
      createCookie("mealprep-session", res.data.data.token);
      createCookie("mealprep-refresh", res.data.data.refreshToken);

      setCurrentUser(res.data.data.user);
      setUser(res.data.data.user);
      setPayload({ ...payload, userId: res.data.data.user.id });

      setCurrentStep((prev) => prev + 1);
    } catch (error) {
      console.log(error);
    }
  };

  const onUpdateUserInfo = async (data, userID) => {
    try {
      const res = await updateUser(data, userID);
      console.log(res);

      setCurrentStep((prev) => prev + 1);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data) => {
    let reqType = REQUEST_TYPES.CREATE;

    if (userInfo) {
      reqType = REQUEST_TYPES.UPDATE;
    }

    switch (reqType) {
      case REQUEST_TYPES.CREATE:
        // create user
        await onCreateUser(data);
        break;

      case REQUEST_TYPES.UPDATE:
        // update user info
        await onUpdateUserInfo(data, userInfo.id);
        break;
      default:
        console.log(reqType);
    }
  };

  useEffect(() => {
    if (userInfo) {
      // console.log(userInfo);
      populateDeliveryForm();
    }
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="text-start pb-7 border-b">
          <p className="text-2xl font-bold mb-2">Delivery Details</p>
          <p>
            Facilitate a seamless and timely delivery by providing accurate
            delivery details, ensuring your delicious meals arrive promptly at
            your doorstep.
          </p>
        </div>
        <div className="xl:w-3/4 my-10">
          <div className="grid grid-cols-2 gap-3">
            <div className="form-control w-full">
              <input
                type="text"
                className="border border-[#E3E3E3] rounded-lg text-xs w-full h-[42px] px-4"
                {...register("firstName")}
                placeholder="First Name"
              />
              {errors.firstName && (
                <div className="text-left text-xs text-red-500 my-2">
                  {errors.firstName?.message}
                </div>
              )}
            </div>
            <div className="form-control w-full">
              <input
                type="text"
                className="border border-[#E3E3E3] rounded-lg text-xs w-full h-[42px] px-4"
                {...register("lastName")}
                placeholder="Last Name"
              />
              {errors.lastName && (
                <div className="text-left text-xs text-red-500 my-2">
                  {errors.lastName?.message}
                </div>
              )}
            </div>
            <div className="form-control w-full">
              <input
                type="text"
                className="border border-[#E3E3E3] rounded-lg text-xs w-full h-[42px] px-4"
                {...register("mobileNumber")}
                placeholder="Phone Number"
              />
              {errors.mobileNumber && (
                <div className="text-left text-xs text-red-500 my-2">
                  {errors.mobileNumber?.message}
                </div>
              )}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-3 mt-6">
            <div className="form-control w-full">
              <input
                type="text"
                className="border border-[#E3E3E3] rounded-lg text-xs w-full h-[42px] px-4"
                {...register("profile.addressLine1")}
                placeholder="Address Line 1"
              />
              {errors.profile?.addressLine1 && (
                <div className="text-left text-xs text-red-500 my-2">
                  {errors.profile?.addressLine1?.message}
                </div>
              )}
            </div>
            <div className="form-control w-full">
              <input
                type="text"
                className="border border-[#E3E3E3] rounded-lg text-xs w-full h-[42px] px-4"
                {...register("profile.addressLine2")}
                placeholder="Address Line 2"
              />
              {errors.profile?.addressLine2 && (
                <div className="text-left text-xs text-red-500 my-2">
                  {errors.profile?.addressLine2?.message}
                </div>
              )}
            </div>
            <div className="form-control w-full">
              <input
                type="text"
                className="border border-[#E3E3E3] rounded-lg text-xs w-full h-[42px] px-4"
                {...register("profile.city")}
                placeholder="City"
              />
              {errors.profile?.city && (
                <div className="text-left text-xs text-red-500 my-2">
                  {errors.profile?.city?.message}
                </div>
              )}
            </div>
            <div className="grid grid-cols-2 gap-3">
              <div className="form-control w-full">
                <input
                  type="text"
                  className="border border-[#E3E3E3] rounded-lg text-xs w-full h-[42px] px-4"
                  {...register("profile.province")}
                  placeholder="Province"
                />
                {errors.profile?.province && (
                  <div className="text-left text-xs text-red-500 my-2">
                    {errors.profile?.province?.message}
                  </div>
                )}
              </div>
              <div className="form-control w-full">
                <input
                  type="text"
                  className="border border-[#E3E3E3] rounded-lg text-xs w-full h-[42px] px-4"
                  {...register("profile.postalCode")}
                  placeholder="Postal"
                />
                {errors.profile?.postalCode && (
                  <div className="text-left text-xs text-red-500 my-2">
                    {errors.profile?.postalCode?.message}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="text-start mt-6">
            <p className="text-sm mb-3">Special Instructions</p>

            <div className="xl:w-1/2">
              <div className="form-control w-full">
                <input
                  type="text"
                  className="border border-[#E3E3E3] rounded-lg text-xs w-full h-[42px] px-4"
                  {...register("profile.deliveryNote")}
                  placeholder="Leave at Door"
                />
                {errors.profile?.deliveryNote && (
                  <div className="text-left text-xs text-red-500 my-2">
                    {errors.profile?.deliveryNote?.message}
                  </div>
                )}
              </div>
              <button className="bg-black w-full h-[36px] text-white rounded-[8px] text-xs mt-6">
                Continue
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Address;
