import React from "react";
import { Navigate, Outlet } from "react-router-dom";
// import { getCurrentUser } from "../services/authService";
import { AuthState } from "../context/authContext";

function PrivateRoute() {
  const { user } = AuthState();
  // console.log(user);
  // const user = getCurrentUser();
  if (!user) return <Navigate to={"/login"} />;
  return <Outlet />;
}

export default PrivateRoute;
