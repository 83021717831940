import React, { useState } from "react";
import WithOrders from "./WithOrders";
import EditProfile from "./EditProfile";
import { getCurrentUser } from "../services/authService";

const OrdersMobile = () => {
    const [clickedBox, setClickedBox] = useState(0);
    const user = getCurrentUser();

    const handleBoxClick = (index) => {
        setClickedBox(index);
    };

    return (
        <div className="block xl:hidden">

            <div className="m-8">
                <p className="xl:mt-[15px] text-[20px] font-semibold text-start">
                    Hi {user.firstName},
                </p>
            </div>
            <div className="mx-8 shadow-lg bg-white">
            <div className="space-x-5 space-y-5">
                    <button
                        // className={` border rounded-lg ${clickedBox === 0 ? "bg-[#FF0000]" : "bg-white"}`} //*RED_THEME button
                        className={` border rounded-lg ${clickedBox === 0 ? "bg-black" : "bg-white"}`}
                        onClick={() => handleBoxClick(0)}
                    >
                        
                        {/* <p className={`m-2 font-semibold ${clickedBox === 0 ? "text-white" : "text-[#FF0000]"}`}>My Orders</p> */} 
                        <p className={`m-2 font-semibold ${clickedBox === 0 ? "text-white" : "text-black"}`}>My Orders</p>
                    </button>

                    <button
                        // className={`border rounded-lg ${clickedBox === 2 ? "bg-[#FF0000]" : "bg-white"}`} //*RED_THEME button
                        className={` border rounded-lg ${clickedBox === 2 ? "bg-black" : "bg-white"}`}
                        onClick={() => handleBoxClick(2)}
                    >
                        
                        {/* <p className={`m-2 font-semibold ${clickedBox === 2 ? "text-white" : "text-[#FF0000]"}`}>My Profile</p> */} 
                        <p className={`m-2 font-semibold ${clickedBox === 2 ? "text-white" : "text-black"}`}>My Profile</p>
                    </button>
                </div>
                <hr className="border-[#D7D7D7]  my-8 mx-8"/>
            <div className=" bg-white  w-96 h-[640px] overflow-y-scroll">
                {clickedBox === 0 && <WithOrders />}
                {clickedBox === 2 && <EditProfile />}
            </div>
            </div>
        </div>
    );
};

export default OrdersMobile;
