// import React from "react";
// import Footer from "../../components/Footer";
// import CountUp from "react-countup";

// const AboutUs = () => {
//   const box = {
//     width: "880px",
//     minWidth: "235px",
//     height: "320px",
//     minHeight: "295",
//   };

//   return (
//     <div>
//       <div className="grid grid-cols-1 justify-items-center xl:mt-[120px]">
//         <div style={box} className="justify-items-center">
//           <p className="text-4xl font-bold">About Us</p>
//           <br />
//           <br />
//           <p className="text-[18px] font-normal text-center">
//             Welcome to MealPrep, where we understand the challenges of
//             maintaining a nutritious lifestyle amidst the demands of everyday
//             life. Our passion lies in providing you with an effortless way to
//             enjoy fresh, chef-prepared meals without sacrificing taste or
//             nutrition. Our team of culinary experts is dedicated to crafting a
//             diverse menu of mouthwatering dishes, using only the finest locally
//             sourced ingredients whenever possible. Whether you're a busy
//             professional, a fitness enthusiast, or a family looking to simplify
//             mealtime, MealPrep is here to support you on your journey to better
//             health. From customized meal plans to individual orders, we're
//             committed to providing you with a seamless experience from start to
//             finish. We take pride in using only the finest, locally sourced
//             ingredients to create meals that are not only delicious but also
//             packed with essential nutrients. Our menu is designed to offer a
//             well-balanced and wholesome dining experience, covering everything
//             from proteins to carbs. Say goodbye to the tedious task of meal
//             preparation, and let us handle the cooking while you savor the
//             convenience of having nutritious meals delivered straight to your
//             door.
//           </p>
//           <br />
//           <p className="text-[18px] font-normal text-center">
//             At MealPrep, we go beyond just delivering food - we deliver an
//             experience tailored to your health and taste preferences.
//           </p>
//         </div>

//         <div className="grid grid-rows-1 grid-flow-col gap-4 justify-items-center xl:mt-[75px] xl:space-x-[156px] xl:mb-[59px]">
//           <div>
//             <p className="text-[40px] font-bold">
//               <CountUp start={0} end={35} duration={3}></CountUp>+
//             </p>
//             <p className="text-[14px] font-bold">MEALS</p>
//           </div>
//           <div>
//             <p className="text-[40px] font-bold">
//               <CountUp start={0} end={1500} duration={2.95}></CountUp>+
//             </p>
//             <p className="text-[14px] font-bold">DAILY MOVE</p>
//           </div>
//           <div>
//             <p className="text-[40px] font-bold">
//               <CountUp start={0} end={45} duration={3}></CountUp>+
//             </p>
//             <p className="text-[14px] font-bold">SUPER MARKET</p>
//           </div>
//           <div>
//             <p className="text-[40px] font-bold">
//               <CountUp start={0} end={55} duration={3}></CountUp>+
//             </p>
//             <p className="text-[14px] font-bold">SUPER MARKET</p>
//           </div>
//         </div>
//         <div className="grid grid-rows-1 grid-flow-col gap-5 justify-items-center mb-[77px]">
//           <div className="bg-[#EEEEEE] w-[300px] h-[301px] rounded-[12px]" />
//           <div className="bg-[#EEEEEE] w-[300px] h-[301px] rounded-[12px]" />
//           <div className="bg-[#EEEEEE] w-[300px] h-[301px] rounded-[12px]" />
//           <div className="bg-[#EEEEEE] w-[300px] h-[301px] rounded-[12px]" />
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default AboutUs;

import React from "react";
import Footer from "../../components/Footer";
import CountUp from "react-countup";

const AboutUs = () => {
  // const box = {
  //   width: "880px",
  //   minWidth: "235px",
  //   height: "auto",
  //   minHeight: "295px",
  // };

  return (
    <div>
      <div className="grid grid-cols-1 justify-items-center mx-auto">
        <div className="justify-items-center p-5 mx-auto xl:px-96">
          <p className="text-4xl font-bold text-center">About Us</p>
          <br />
          <p className="text-sm xl:text-lg font-normal text-justify">
            Welcome to MealPrep, where we understand the challenges of
            maintaining a nutritious lifestyle amidst the demands of everyday
            life. Our passion lies in providing you with an effortless way to
            enjoy fresh, chef-prepared meals without sacrificing taste or
            nutrition. Our team of culinary experts is dedicated to crafting a
            diverse menu of mouthwatering dishes, using only the finest locally
            sourced ingredients whenever possible. Whether you're a busy
            professional, a fitness enthusiast, or a family looking to simplify
            mealtime, MealPrep is here to support you on your journey to better
            health. From customized meal plans to individual orders, we're
            committed to providing you with a seamless experience from start to
            finish. We take pride in using only the finest, locally sourced
            ingredients to create meals that are not only delicious but also
            packed with essential nutrients. Our menu is designed to offer a
            well-balanced and wholesome dining experience, covering everything
            from proteins to carbs. Say goodbye to the tedious task of meal
            preparation, and let us handle the cooking while you savor the
            convenience of having nutritious meals delivered straight to your
            door.
          </p>
          <br />
          <p className="xl:text-lg font-normal text-justify">
            At MealPrep, we go beyond just delivering food - we deliver an
            experience tailored to your health and taste preferences.
          </p>
        </div>

        <div className="grid xl:grid-rows-1 xl:grid-flow-col gap-4 justify-items-center mx-auto xl:space-x-40 mt-20 space-y-40 xl:space-y-0">
          
          <div>
            <p className="text-4xl font-bold">
              <CountUp start={0} end={35} duration={3}></CountUp>+
            </p>
            <p className="text-base font-bold text-center">MEALS</p>
          </div>
          <div>
            <p className="text-4xl font-bold">
              <CountUp start={0} end={1500} duration={2.95}></CountUp>+
            </p>
            <p className="text-base font-bold text-center">DAILY MOVE</p>
          </div>
          <div>
            <p className="text-4xl font-bold">
              <CountUp start={0} end={45} duration={3}></CountUp>+
            </p>
            <p className="text-base font-bold text-center">SUPER MARKET</p>
          </div>
          <div>
            <p className="text-4xl font-bold">
              <CountUp start={0} end={55} duration={3}></CountUp>+
            </p>
            <p className="text-base font-bold text-center">SUPER MARKET</p>
          </div>
          
        </div>

        <div className="grid xl:grid-rows-1 xl:grid-flow-col gap-5 justify-items-center mb-20 mt-20">
          <div className="bg-gray-200 w-60 h-60 rounded-lg" />
          <div className="bg-gray-200 w-60 h-60 rounded-lg" />
          <div className="bg-gray-200 w-60 h-60 rounded-lg" />
          <div className="bg-gray-200 w-60 h-60 rounded-lg" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
