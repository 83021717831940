import dayjs from "dayjs";
import React from "react";

const OrderCard = ({ order }) => {
  return (
    <div className="border border-[#D9D5D5] rounded-lg">
      <div className="text-start divide-y-[1px]">
        <div className="p-5">
          <p className="text-sm text-[#888888]">
            Date: {dayjs.unix(order.createdAt).format("MMM DD, YYYY")} | Order
            No : #{order.id}
          </p>
          <div className="mt-4 flex space-x-3 mb-5">
            <p className="xl:text-[18px] font-bold">
              {order.categories.length === 1
                ? order.categories[0].name
                : "Multi Select"}
            </p>
            <div className="rounded-[50px] bg-orange-100 text-red-500 w-[80px] h-[24px] justify-center items-center flex">
              <p className="text-[10px]">{order.status}</p>
            </div>
          </div>
          {order.deliveryInfo && (
            <p className="text-sm text-[#888888]">
              {order.deliveryInfo.addressLine1},{" "}
              {order.deliveryInfo.addressLine2}, {order.deliveryInfo.city},{" "}
              {order.deliveryInfo.province}, {order.deliveryInfo.postalCode}.
            </p>
          )}
        </div>
        <div className="p-5">
          <div className="flex justify-between items-center">
            <p className="text-sm my-2">
              {order.plan.noOfMeals} meals per week
            </p>
            <p className="font-bold">Total: ${order.totalAmount}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
