import React from 'react';
import FOOTER from './Footer';
import { Link, useNavigate } from "react-router-dom";

const PageNotFound = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div className='justify-center items-center p-5'>

                <div className='flex justify-center items-center p-5'>
                    <hr className='xl:w-[200px] border-black justify-center items-center' />
                    <div>
                        <p className='xl:text-[250px] text-8xl font-semibold font-sans text-[#FF0000]'>404</p>
                        <p className='xl:text-[50px] text-2xl font-[200]'>OOPS ! SORRY WE CAN'T FIND THAT PAGE !</p>
                        <p className='xl:text-[25px]  text-xs font-[200] m-4'>EITHER SOMETHING WENT WRONG OR PAGE DOES NOT EXIST.</p>
                    </div>
                    <hr className='xl:w-[200px] border-black justify-center items-center' />
                </div>

                <button onClick={() => navigate('/')} className="bg-[#FF0000] text-white xl:text-[32px] xl:w-[250px] w-28 h-10 xl:h-[70px] w- xl:mb-56 rounded-full"><p>HOME PAGE</p></button>


            </div>
            <FOOTER />
        </div>
    );
}

export default PageNotFound;
