import axios from "axios";
import { refreshToken } from "../services/authService";

export const axiosHttp = axios.create({
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

axiosHttp.interceptors.response.use(
  (response) => response,
  async (error) => {
    const prevRequest = error?.config;
    if (error.response.status === 401 && !prevRequest?.sent) {
      prevRequest.sent = true;
      const res = await refreshToken();
      console.log(res);
      return axiosHttp(prevRequest);
    }
    return Promise.reject(error);
  }
);
