import React, { useEffect, useState } from "react";
import Register from "./Register";
import Address from "./Address";
import SelectMeal from "./SelectMeal";
import SelectPlan from "./SelectPlan";
import { getCurrentUser } from "../services/authService";
import { findOneUser } from "../services/userServices";

const steps = [
  {
    id: 1,
    name: "Select Plan",
  },
  {
    id: 2,
    name: "Register",
  },
  {
    id: 3,
    name: "Address",
  },
  // {
  //   id: 4,
  //   name: "Checkout",
  // },
  {
    id: 4,
    name: "Select Meals",
  },
];

function StepForm() {
  const [currentStep, setCurrentStep] = useState(1);
  const [payload, setPayload] = useState({});
  const [registerForm, setRegisterForm] = useState();
  const [user, setUser] = useState(null);
  const currentUser = getCurrentUser() || null;

  const getOneUser = async () => {
    try {
      const res = await findOneUser(currentUser?.id);
      // console.log(res.data.data);
      setUser(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (currentUser?.id) {
  //     getOneUser();
  //     if (currentStep < 3) {
  //       setCurrentStep(3);
  //     }
  //   }
  // }, [currentUser, currentStep]);

  useEffect(() => {
    if (currentUser?.id) {
      getOneUser();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  return (
    <>
      <div className="xl:w-9/12 mx-auto">
        {/* step counter */}
        <div className="flex justify-around mb-10">
          {/* item */}
          {steps.map((s) => (
            <div key={s.id} className="flex flex-col items-center gap-2">
              <div
                className={`w-10 h-10 text-sm font-semibold flex items-center justify-center rounded-full ${
                  currentStep >= s.id
                    ? "bg-[#E30032] text-white"
                    : "bg-white text-black"
                }`}
              >
                {s.id.toString().padStart(2, "0")}
              </div>
              <p
                className={`font-semibold text-xs uppercase ${
                  currentStep >= s.id ? "text-black" : "text-[#888]"
                }`}
              >
                {s.name}
              </p>
            </div>
          ))}
        </div>
        <div className="border border-gray bg-white rounded-lg p-8">
          {currentStep === 1 && (
            <SelectPlan
              setCurrentStep={setCurrentStep}
              payload={payload}
              setPayload={setPayload}
              user={user}
            />
          )}
          {currentStep === 2 && (
            <Register
              setCurrentStep={setCurrentStep}
              // payload={payload}
              // setPayload={setPayload}
              setRegisterForm={setRegisterForm}
            />
          )}
          {currentStep === 3 && (
            <Address
              setCurrentStep={setCurrentStep}
              payload={payload}
              setPayload={setPayload} 
              registerForm={registerForm}
              userInfo={user}
            />
          )}
          {/* {currentStep === 4 && <Checkout setCurrentStep={setCurrentStep} />} */}
          {currentStep === 4 && (
            <SelectMeal payload={payload} setPayload={setPayload} />
          )}
        </div>
      </div>
    </>
  );
}

export default StepForm;
