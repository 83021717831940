import { BASE_URL } from "../constants/settings";
import { axiosHttp } from "../intercepters/axiosHandler";

export const findOneUser = async (userID) => {
  try {
    return Promise.resolve(
      await axiosHttp.get(`${BASE_URL}/api/v1/users/${userID}`)
    );
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateUser = async (payload, userID) => {
  try {
    return Promise.resolve(
      await axiosHttp.patch(`${BASE_URL}/api/v1/users/${userID}`, payload)
    );
  } catch (err) {
    return Promise.reject(err);
  }
};
