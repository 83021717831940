import React, { useEffect, useState } from "react";

import MenuCard from "../../components/MenuCard.js";
import Footer from "../../components/Footer.js";
import img1 from "../../images/image 7-1.png";

import { findMealsPaginated } from "../../services/mealService.jsx";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek.js";
import PaginationButtons from "../../utils/paginationButtons.jsx";
import { useNavigate, useParams } from "react-router-dom";

dayjs.extend(isoWeek);

const WeeklyMenu = () => {
  const { weekID } = useParams();
  const navigate = useNavigate();
  const [meals, setMeals] = useState([]);

  const [mealsCount, setMealsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 8;
  const totalPages = Math.ceil(mealsCount / itemsPerPage) || 1;

  // const [selection, setSelection] = useState(0);

  const handleSelection = (weekNo) => {
    navigate(`/weeklyMenu/${weekNo}`);
    setCurrentPage(0);
  };

  const getMeals = async () => {
    const payload = {
      page: Math.min(currentPage + 1, totalPages),
      limit: itemsPerPage,
      menuStartDate: dayjs()
        .add(weekID, "week")
        .isoWeekday(1)
        .format("YYYY-MM-DD"),
      menuEndDate: dayjs()
        .add(weekID, "week")
        .isoWeekday(7)
        .format("YYYY-MM-DD"),
      keywords: "",
    };

    const res = await findMealsPaginated(payload);
    setMeals(res.data?.data?.list);
    setMealsCount(res.data?.data?.count);
  };

  const findWeekRange = (weekNo) => {
    const start = dayjs().add(weekNo, "week").isoWeekday(1).format("MMM DD");
    const end = dayjs().add(weekNo, "week").isoWeekday(7).format("MMM DD");

    return `${start} - ${end}`;
  };

  useEffect(() => {
    getMeals();
    window.scrollTo(0, 0);
  }, [currentPage, weekID]);

  return (
    <>
      <div className="bg-creamWhite py-20 px-4 xl:px-0">
        <div className="container mx-auto px-5">
          <h1 className="text-2xl xl:text-4xl font-bold">
            Explore Our Flexible Weekly Menu
          </h1>

          <div className="flex gap-4 justify-center my-10">
            <button
              onClick={() => handleSelection(0)}
              className={`border border-black rounded-xl px-2 xl:px-6 h-[42px] text-xs xl:text-sm font-medium ${
                weekID === "0" ? "bg-black text-white" : ""
              }`}
            >
              {findWeekRange(0)}
            </button>
            <button
              onClick={() => handleSelection(1)}
              className={`border border-black rounded-xl px-2 xl:px-6 h-[42px] text-xs xl:text-sm font-medium ${
                weekID === "1" ? "bg-black text-white" : ""
              }`}
            >
              {findWeekRange(1)}
            </button>
            <button
              onClick={() => handleSelection(2)}
              className={`border border-black rounded-xl px-2 xl:px-6 h-[42px] text-xs xl:text-sm font-medium ${
                weekID === "2" ? "bg-black text-white" : ""
              }`}
            >
              {findWeekRange(2)}
            </button>
          </div>
          <>
            <div className="grid grid-cols-1 xl:grid-cols-4 gap-4">
              {meals.map((data, idx) => {
                return <MenuCard key={idx} imgUrl={data.imageUrl} data={data} />;
              })}
            </div>
            {meals.length !== 0 ? (
              <div className="flex justify-center mt-10">
                <PaginationButtons
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            ) : (
              <>
                <div className="flex justify-center items-center border-[1px] border-[#EEDAA8] bg-[#FFF6E3] rounded-[10px] h-[60px]">
                  <p className="text-[#EBB346] font-medium">
                    No menu items found.
                  </p>
                </div>
              </>
            )}
          </>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WeeklyMenu;
