import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import {
  findReceiptByPaymentID,
  findSessionByID,
} from "../services/orderService";
import Payment from "../components/Payment";

function CheckOut() {
  const { orderID, sessionID } = useParams();
  const [session, setSession] = useState(null);

  const getSessionByID = async () => {
    try {
      const res = await findSessionByID({ orderID, sessionID });
      console.log(res.data.data);
      setSession(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const donwnloadReceipt = async () => {
    try {
      await findReceiptByPaymentID(session.payment.id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSessionByID();
  }, []);

  return (
    <>
      <div className="bg-creamWhite py-20">
        {session?.order.status === "PLACED" && (
          <div className="w-[600px] mx-auto bg-white border rounded-lg p-10 flex flex-col items-center gap-3">
            <RotatingLines strokeColor="#FF0000" />
            <h1 className="text-xl font-semibold">
              Waiting for payment to complete
            </h1>
            <p>
              If you're not directed to complete the payment already{" "}
              <a
                className="underline font-medium cursor-pointer hover:text-red-500 duration-300"
                href={session?.checkoutSession.url}
                target="_blank"
                rel="noreferrer"
              >
                click here
              </a>{" "}
              to continue
            </p>
          </div>
        )}

        {session?.order.status === "PAID" && (
          <Payment
            order={session.order}
            user={session.user}
            donwnloadReceipt={donwnloadReceipt}
          />
        )}
      </div>
      <Footer />
    </>
  );
}

export default CheckOut;
